import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { startBatch } from 'services/cuttingService';

export default function SawCutting({
  barcode,
  startStage,
  product,
  isWorking,
  stationId,
  setIsShowSawCuttingSetting,
  updateQueue,
  userId,
  onScanStarted,
  onStartBatch,
  onScanCompleted,
  setProduct
}) {
  const [cuttingItems, setCuttingItems] = useState([]);
  const [batchNo, setBatchNo] = useState(0);
  const [subBatchNo, setSubBatchNo] = useState(0);
  const [status, setStatus] = useState('N/A');

  const [isLoading, setIsLoading] = useState(false);
  const sawCuttingProduct = useSelector(
    store => store.widget.sawCuttingProduct
  );

  useEffect(() => {
    if (barcode > 0) {
      setIsShowSawCuttingSetting(true);
      updateQueue('isShowSawCuttingSetting');
    } else {
      setBatchNo(0);
      setSubBatchNo(0);
      setCuttingItems([]);
      setStatus('');
    }
  }, [barcode]);

  useEffect(() => {
    if (barcode > 0) {
      setIsLoading(true);
      startBatch(
        barcode,
        startStage,
        userId,
        stationId,
        sawCuttingProduct.product
      )
        .then(res => {
          if (res.success && res.data) {
            setCuttingItems(res?.data?.batchData);
            setBatchNo(res?.data?.batchNo);
            setSubBatchNo(res?.data?.subBatchNo);
            setStatus('Cutting');
            setIsLoading(false);
            onScanStarted();
            onStartBatch(res?.data);
            onScanCompleted(res?.data?.userTrackingId);
          }
        })
        .catch(err => {
          swalToastError('Error! Get Batch Info Failed.');
        });
    } else {
      setBatchNo(0);
      setSubBatchNo(0);
      setCuttingItems([]);
      setStatus('');
    }
  }, [sawCuttingProduct]);

  return !isLoading ? (
    <>
      <h5>
        {batchNo} / {subBatchNo}
      </h5>
      <h5>Status: {status}</h5>
      <br></br>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Slot No</th>
            <th>Job No</th>
            <th>Win No</th>
            <th>Sash No</th>
            <th>Frame Size</th>
            <th>Group</th>
            <th>Profile</th>
          </tr>
        </thead>
        <tbody>
          {cuttingItems.length > 0 ? (
            cuttingItems.map((item, ind) => (
              <tr key={'row' + ind}>
                <td>{item.slotNumber}</td>
                <td>{item.jobNo}</td>
                <td>{item.winNo}</td>
                <td>{item.sashNo}</td>
                <td>{`${item.width} x ${item.length}`} </td>
                <td>{item.groupDesc}</td>
                <td>{item.pCode}</td>
              </tr>
            ))
          ) : (
            <tr key={'row0'}>
              <td colSpan={7}>No records found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  ) : (
    <StationSpinner></StationSpinner>
  );
}
