import axios from 'axios';
import queryString from 'query-string';

axios.defaults.withCredentials = true;

const axiosHelper = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  paramsSerializer: params => queryString.stringify(params)
});

axiosHelper.interceptors.response.use(
  response => {
    if (!response.data) {
      return response;
    }
    return {
      data: response.data,
      success: response.status >= 200 && response.status < 300
    };
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('login-data');
      window.location.reload();
    } else {
      throw error;
    }
  }
);

export default axiosHelper;
