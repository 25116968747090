import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getUserCuttingLogs } from 'services/trackingService';

export default function UserLogV2({ completedStage, userId, realName }) {
  const intervalTime = 30000;

  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let today = dayjs(); // get logs for today only

  let isRendered = useRef(false);
  useEffect(() => {
    isRendered = true;
    loadLogsTable(today, userId, completedStage);

    const intervalCheck = setInterval(() => {
      loadLogsTable(today, userId, completedStage);
    }, intervalTime);

    return () => {
      isRendered = false;
      clearInterval(intervalCheck);
    };
  }, []);

  const loadLogsTable = (date, userId, stageId) => {
    setIsLoading(true);
    getUserCuttingLogs(date, userId, stageId)
      .then(res => {
        setIsLoading(false);
        if (res.success && res.data) {
          setLogs(res.data);
        } else {
          swalToastError(`Could not get user logs. Please try again.`);
        }
      })
      .catch(err => {
        swalToastError('Error. Something went wrong.');
      });
  };

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div>
      <h5>
        Hi <b>{realName}</b>
      </h5>
      <h5>View logs for {today.format('DD/MM/YYYY')}</h5>
      <h5>Update @ {dayjs().format('DD/MM/YYYY HH:mm:ss')}</h5>
      <Table className="h6 table-sm" style={{ fontSize: '12px' }}>
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Stage</th>
            <th>Job No.</th>
            <th>BoxId</th>
            <th>Cut</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td>
              <b>Total Cut: </b>{' '}
            </td>
            <td colSpan={2}>
              <b>
                {logs.length > 0 ? logs.reduce((ac, c) => ac + c.cut, 0) : 0}
              </b>
            </td>
          </tr>
          {logs.length > 0 ? (
            logs.map((item, index) => (
              <tr key={index}>
                <td>{item.stageName}</td>
                <td>{item.jobNo}</td>
                <td>{item.boxId}</td>
                <td>{item.cut}</td>
                <td>{dayjs(item.startTime).format('HH:mm')}</td>
              </tr>
            ))
          ) : (
            <tr key={'row0'}>
              <td colSpan={6}>No log found.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
