import { HotTable } from '@handsontable/react';
import StationSpinner from 'components/station/common/Spinner';
import { colourData } from 'data/stations/colourData';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import {
  swalToastError,
  swalToastInfo,
  swalToastSuccess
} from 'helpers/component-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  deleteS3File,
  getAttachmentByRecordId
} from 'services/attachmentService';
import {
  deleteFilterItemById,
  getFilterItemsByGroup,
  updateFilterItemsSetup
} from 'services/filterService';

registerAllModules();

export default function FilterItemsTable(props) {
  const hotRef = useRef(null);
  const group = props.group;
  let colorNames = [];
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modalConfirmDeleteShow, setModalConfirmDeleteShow] = useState(false);
  const [deletedFilterId, setDeletedFilterId] = useState(0);
  const [deletedS3Path, setDeletedS3Path] = useState('');

  colourData.forEach(function (item) {
    colorNames.push(item.name);
  });

  useEffect(() => {
    if (group != '') {
      doGetItems(group);
    }
  }, [group]);

  const doGetItems = group => {
    setIsLoading(true);
    getFilterItemsByGroup(group)
      .then(res => {
        if (res.success && res.data) {
          res.data.forEach(function (item, index) {
            item.bgColour =
              colourData.find(x => x.value == item.bgColour).name ?? 'White';
            item.fontColour =
              colourData.find(x => x.value == item.fontColour).name ?? 'Black';
          });
          setTableData(res.data);
        }
        setIsLoading(false);
      })
      .catch(error => {
        swalToastError('Get Filter Item Failed!');
      });
  };

  //const openAddFilterModal = () => {};

  const saveClickCallback = () => {
    const hot = hotRef.current.hotInstance;
    const tableLatestData = hot.getData();
    let payload = [];

    //console.log(tableLatestData);

    if (tableLatestData.length > 0) {
      tableLatestData.forEach(function (item, index) {
        let filterItem = {
          id: item[0],
          filterGroupName: item[1],
          filterName: item[2],
          bgColour: colourData.find(x => x.name == item[3]).value,
          fontColour: colourData.find(x => x.name == item[4]).value,
          fontSize: item[5],
          fontBold: (item[6] == 'true' || item[6] == true) ?? false,
          fontItalic: (item[7] == 'true' || item[7] == true) ?? false,
          fontUnderline: (item[8] == 'true' || item[8] == true) ?? false,
          fontStroke: (item[9] == 'true' || item[9] == true) ?? false
        };
        payload.push(filterItem);
      });

      updateFilterItemsSetup(payload)
        .then(res => {
          if (res.success && res.data) {
            swalToastSuccess(`Filter Items have been saved`);
            doGetItems(group);
            props.onDataChanged();
          } else {
            swalToastError(`Failed to save Filter Items`);
          }
        })
        .catch(error => {
          swalToastError('Failed to save Filter Items');
        });
    } else {
      swalToastInfo(`No records to save`);
    }
  };

  const handleCellClick = (e, coords, TD) => {
    if (coords.col == 10) {
      let filterId = hotRef.current.hotInstance.getDataAtCell(coords.row, 0);

      //delete button
      setDeletedFilterId(filterId);
      setModalConfirmDeleteShow(true);

      getAttachmentByRecordId(filterId, 'product.image')
        .then(res => {
          if (res.success && res?.data) {
            setDeletedS3Path(res?.data.path.replace('s3/eva-files/', ''));
          } else {
            setDeletedS3Path('');
          }
        })
        .catch(error => {
          swalToastError('Get Attachment Failed!');
        });
    }
  };

  const onDeleteFilterItem = () => {
    deleteFilterItemById(deletedFilterId)
      .then(res => {
        if (res?.success && res.data) {
          if (deletedS3Path) {
            deleteS3File(deletedS3Path).then(res1 => {
              if (res1?.success) {
                swalToastSuccess(
                  `Filter Item and product image has been deleted`
                );
                props.onDataChanged();
                // doGetItems(group);
                setModalConfirmDeleteShow(false);
              }
            });
          } else {
            swalToastSuccess(`Filter Item has been deleted`);
            // doGetItems(group);
            props.onDataChanged();
            setModalConfirmDeleteShow(false);
          }
        } else {
          swalToastError('Error. Please try again');
        }
      })
      .catch(err => {
        swalToastError('Error. Please try again');
      });
  };

  const renderButton = (
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) => {
    td.innerHTML = `<button type='button' class='btn btn-danger'>Delete</button>`;
  };

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Modal
        show={modalConfirmDeleteShow}
        // dialogClassName="modal-60w"
        size="lg"
        animation={false}
        autoFocus={true}
      >
        <Modal.Header>
          <Modal.Title>Delete Filter Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure you want to delete this filter item?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-danger"
            onClick={onDeleteFilterItem}
          >
            Yes
          </Button>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setModalConfirmDeleteShow(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <HotTable
        data={tableData}
        rowHeaders={false}
        ref={hotRef}
        className="htCenter"
        fixedColumnsStart={1}
        allowInsertRow={true}
        columnSorting={{
          initialConfig: {
            column: 2,
            sortOrder: 'asc'
          }
        }}
        colHeaders={[
          'ID',
          'Filter Group',
          'Filter',
          'B/Col',
          'F/Col',
          'Size',
          'Bold',
          'Italic',
          'Underline',
          'Stroke',
          'Actions'
        ]}
        columns={[
          { data: 'id', readOnly: true },
          { data: 'filterGroupName', readOnly: true },
          { data: 'filterName' },
          { data: 'bgColour', editor: 'select', selectOptions: colorNames },
          {
            data: 'fontColour',
            editor: 'select',
            selectOptions: colorNames
          },
          { data: 'fontSize', type: 'numeric' },
          {
            data: 'fontBold',
            type: 'checkbox',
            checkedTemplate: 'true',
            uncheckedTemplate: 'false'
          },
          {
            data: 'fontItalic',
            type: 'checkbox',
            checkedTemplate: 'true',
            uncheckedTemplate: 'false'
          },
          {
            data: 'fontUnderline',
            type: 'checkbox',
            checkedTemplate: 'true',
            uncheckedTemplate: 'false'
          },
          {
            data: 'fontStroke',
            type: 'checkbox',
            checkedTemplate: 'true',
            uncheckedTemplate: 'false'
          },
          { data: '', renderer: renderButton }
        ]}
        hiddenColumns={{
          columns: [0],
          indicators: true
        }}
        width="100%"
        height="auto"
        stretchH="all"
        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
        afterOnCellMouseDown={(e, coords, TD) => handleCellClick(e, coords, TD)}
      />
      <br></br>
      <Button
        className="btn btn-sm btn-success"
        onClick={props.onOpenAddFilterModal}
      >
        Add New
      </Button>{' '}
      <Button
        className="btn btn-sm btn-primary"
        onClick={(...args) => saveClickCallback(...args)}
      >
        Save
      </Button>
    </>
  );
}
