import axiosHelper from 'helpers/axiosHepler';

const getMachines = () => {
  const url = `/machine-management/machines`;
  return axiosHelper.get(url);
};

const getMachineById = machineId => {
  const url = `/machine-management/machines/${machineId}`;
  return axiosHelper.get(url);
};

const createMachine = payload => {
  const url = `/machine-management/machines`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const updateMachine = payload => {
  const url = `/machine-management/machines`;
  const data = payload;
  return axiosHelper.patch(url, data);
};

const deleteMachine = machineId => {
  const url = `/machine-management/machines/${machineId}`;
  return axiosHelper.delete(url);
};

const getStartUpChecksByMachine = (machineId, app) => {
  const url = `/machine-management/machines/${machineId}/startup-checks?app=${app}`;
  return axiosHelper.get(url);
};

const createStartupCheck = (machineId, payload) => {
  const url = `/machine-management/machines/${machineId}/startup-checks`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const updateCheck = payload => {
  const url = `/machine-management/machines/startup-checks`;
  const data = payload;
  return axiosHelper.patch(url, data);
};

const deleteStartupCheck = checkId => {
  const url = `/machine-management/machines/startup-checks/${checkId}`;
  return axiosHelper.delete(url);
};

const getQAChecklist = barcode => {
  const url = `/Panel/has-checklist?barcode=${barcode}`;
  return axiosHelper.get(url);
};

export {
  getMachines,
  getMachineById,
  createMachine,
  updateMachine,
  deleteMachine,
  getStartUpChecksByMachine,
  createStartupCheck,
  updateCheck,
  deleteStartupCheck,
  getQAChecklist
};
