import axios from 'axios';
import axiosHelper from '../helpers/axiosHepler';

// axios.defaults.withCredentials = true;

export const getAllHingeScrewsMatrixes = () => {
  return axiosHelper.get(`${process.env.REACT_APP_API_HOST}/screws-matrix/all`);
};

export const uploadFile = files => {
  const url = ``;
  const data = files;
  return axiosHelper.post(url, data);
};

export const deleteProfile = id => {
  const url = `/screws-matrix/${id}`;
  return axiosHelper.delete(url);
};

export const updateProfile = payload => {
  const url = `/screws-matrix`;
  const data = payload;
  return axiosHelper.put(url, data);
};

export const getScrewsProfileByPcodes = pCodes => {
  const url = `/screws-matrix/by-pcodes?pCodes=${pCodes}`;
  return axiosHelper.get(url);
};
