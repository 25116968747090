import MultiSelect from 'components/common/MultiSelect';
import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { getNextWorkingDay } from 'helpers/utils';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Spinner
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {
  getBoxDetail,
  getFrameDetailByHeaderIdAndFrameId
} from 'services/frameService';
import {
  getFramesStillage,
  getStillgePhotos
} from 'services/frameStillageService';
import BoxImage from '../../assets/img/box_image.png';
import AncillaryBoxImage from '../../assets/img/ancillary-box.jpg';
import * as signalR from '@microsoft/signalr';

const optionStatus = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Loaded',
    value: 'loaded'
  },
  {
    label: 'Not Loaded',
    value: 'notloaded'
  }
];

const BayFilerOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const MAXIMUMSTILLAEGFRAMES = 17;

export default function PlottingStillageDashboard() {
  const [deliveryDate, setDeliveryDate] = useState(
    new Date(getNextWorkingDay(new Date()))
  );
  const [jobs, setJobs] = useState([]);
  const [statusOption, setStatusOption] = useState({
    label: 'All',
    value: 'all'
  });
  const [isShowBayDefault, setIsShowBayDefault] = useState({
    label: 'Yes',
    value: true
  });
  const [customers, setCustomers] = useState([]);
  const [status, setStatus] = useState('all');
  const [custCodes, setCustCodes] = useState([]);
  const [isShowBay, setIsShowBay] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [jobsFiltered, setJobsFiltered] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(
    dayjs(new Date()).format('HH:mm')
  );
  const [itemDetail, setItemDetail] = useState({});
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [isloadingDetail, setIsLoadingDetail] = useState(false);
  const [dataCommingChange, setDataCommingChange] = useState({});
  const [Api2connection, setApi2Connection] = useState();
  const [evaSSConnection, setEvaSSConnection] = useState();
  const [isShowStillagePhotos, setIsShowStillagePhotos] = useState(false);
  const [isLoadingStillagePhotos, setIsLoadingStillagePhotos] = useState(false);
  const [stillagePhotos, setStillagePhotos] = useState([]);

  useEffect(() => {
    // copllasped side navbar.
    document
      .getElementsByTagName('html')[0]
      .classList.add('navbar-vertical-collapsed');

    // sign up connection to web socket for the page
    const Api2Connection = new signalR.HubConnectionBuilder()
      .withUrl(`https://api2-dev.emplas.co.uk/v1/plotting-stillages-dashboard`)
      .build();

    const evaSSConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_HOST}/plotting-stillages-dashboard`)
      .build();

    // listener when server trigger "ReceiveMessage" event.
    Api2Connection.on('ReceiveMessage', data => {
      setDataCommingChange(data);
    });

    // listener when server trigger "ReceiveMessage" event.
    evaSSConnection.on('EVASSReceiveMessage', data => {
      setDataCommingChange(data);
    });

    Api2Connection.start()
      .then(() => console.log('Connected to API 2 SignalR hub'))
      .catch(error =>
        console.error('Error connecting to API 2 SignalR hub:', error)
      );

    evaSSConnection
      .start()
      .then(() => console.log('Connected to Eva SS SignalR hub'))
      .catch(error =>
        console.error('Error connecting to  Eva SS  SignalR hub:', error)
      );

    setApi2Connection(Api2Connection);
    setEvaSSConnection(evaSSConnection);

    return () => {
      Api2Connection.stop()
        .then(() => console.log('Disconnected to Api 2 SignalR hub'))
        .catch(error =>
          console.error('Error disconnecting to Api-2 SignalR hub:', error)
        );

      evaSSConnection
        .stop()
        .then(() => console.log('Disconnected to Eva SS SignalR hub'))
        .catch(error =>
          console.error('Error disconnecting to Eva SS SignalR hub:', error)
        );
    };
  }, []);

  useEffect(() => {
    syncJobDataViaWebSocket(dataCommingChange, jobs);
  }, [dataCommingChange]);

  useEffect(() => {
    fetchData();
  }, [deliveryDate]);

  const fetchData = () => {
    setIsLoading(true);
    getFramesStillage(dayjs(new Date(deliveryDate)).format('YYYY-MM-DD'))
      .then(res => {
        if (res.data && res.data.data && res.data?.data?.jobs.length > 0) {
          const jobs = res.data?.data?.jobs;
          loadedJobToStillage(jobs);
        } else {
          setJobs([]);
        }
        setIsLoading(false);
        setLastUpdatedAt(dayjs(new Date()).format('HH:mm'));
      })
      .catch(error => {
        swalToastError('Get Frames Stilage Failed!');
        setIsLoading(false);
      });
  };

  const syncJobDataViaWebSocket = (data, jobs) => {
    const jobIndex = jobs.findIndex(j => j.headerId == data.headerId);
    if (jobIndex >= 0 && jobs[jobIndex]) {
      let itemIndex;
      if (data.boxId > 0) {
        itemIndex = jobs[jobIndex].boxes.findIndex(
          b => b.headerId === data.headerId && b.boxId === data.boxId
        );
        if (itemIndex >= 0 && jobs[jobIndex].boxes[itemIndex]) {
          jobs[jobIndex].boxes[itemIndex] = {
            ...jobs[jobIndex].boxes[itemIndex],
            maxStageId: data.stageId,
            maxStageName: data.stageName,
            stillageNo: data.stillageNo,
            stillageId: data.stillageId,
            stillageStartTime: data.stillageStartTime
          };
        }
      } else {
        itemIndex = jobs[jobIndex].frames.findIndex(
          f => f.headerId === data.headerId && f.frameId === data.frameId
        );

        if (itemIndex >= 0 && jobs[jobIndex].frames[itemIndex]) {
          jobs[jobIndex].frames[itemIndex] = {
            ...jobs[jobIndex].frames[itemIndex],
            maxStageId: data.stageId,
            maxStageName: data.stageName,
            stillageNo: data.stillageNo,
            stillageId: data.stillageId,
            stillageStartTime: data.stillageStartTime
          };
        }
      }

      if (data.stageId === 75) {
        jobs = jobs.sort((a, b) => {
          if (a.headerId === data.headerId) {
            return -1;
          } else {
            return 0;
          }
        });
      }
      loadedJobToStillage([...jobs]);
    }
  };

  const loadedJobToStillage = jobs => {
    let jobsWithLoadedOnStillage = jobs.map(job => {
      let stillageInfos = [];
      const data = [...job.frames, ...job.boxes];
      if (data.length == 0) {
        stillageInfos = [];
      }
      data.map(item => {
        if (
          item.stillageNo > 0 &&
          !stillageInfos.filter(s => s.stillageNo == item.stillageNo).length > 0
        ) {
          stillageInfos.push({
            stillageNo: item.stillageNo,
            stillageId: item.stillageId,
            startTime: item.stillageStartTime
          });
        }
      });

      return {
        ...job,
        stillageInfos
      };
    });

    setJobs(jobsWithLoadedOnStillage);
  };

  const filterLoaded = jobs => {
    let result = jobs.map(job => {
      return {
        ...job,
        frames: filterData(job.frames, true),
        boxes: filterData(job.boxes, true)
      };
    });
    return result;
  };

  const filterNotLoaded = jobs => {
    return jobs.map(job => {
      return {
        ...job,
        frames: filterData(job.frames, false),
        boxes: filterData(job.boxes, false)
      };
    });
  };

  const filterData = (data, isloaded) => {
    return data.filter(frame =>
      isloaded ? frame.maxStageId >= 75 : frame.maxStageId < 75
    );
  };

  const filterStatus = data => {
    let filtered = data;

    //filter status.
    if (status == 'loaded') {
      filtered = filterLoaded(data);
    } else if (status == 'notloaded') {
      filtered = filterNotLoaded(data);
    }

    return filtered;
  };

  const filterCustomer = data => {
    if (custCodes.length > 0) {
      return data.filter(job => custCodes.includes(job.customerCode));
    } else return data;
  };

  const filterBoxes = (data, isShowBay) => {
    let filter = data;

    if (!isShowBay) {
      filter = filter.filter(box => box.boxType != 'Bay Cill');
    }

    return filter;
  };

  const filterShowBay = jobs => {
    let result = jobs.map(job => {
      return {
        ...job,
        boxes: filterBoxes(job.boxes, isShowBay)
      };
    });
    return result;
  };

  const filterAllData = jobs => {
    let filtered = filterStatus(jobs);
    filtered = filterShowBay(filtered);
    filtered = filterCustomer(filtered);

    filtered = filtered.filter(
      job => job.frames.length > 0 || job.boxes.length > 0
    );
    return filtered;
  };

  useEffect(() => {
    setJobsFiltered(filterAllData(jobs));
  }, [jobs, status, custCodes, isShowBay]);

  const buildCustomerFilterOptions = jobs => {
    const result = jobs.map(job => {
      return {
        label: job.customerName,
        value: job.customerCode
      };
    });

    // remove duplicateed
    const unique = result.reduce((accumulator, current) => {
      if (!accumulator.find(item => item.value === current.value)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return unique;
  };

  const onChangeDeliveryDate = date => {
    setDeliveryDate(date);
  };

  const onChangeStatus = value => {
    setStatusOption(value);
    setStatus(value.value);
  };

  const onChangeShowBay = value => {
    setIsShowBayDefault(value);
    setIsShowBay(value.value);
  };

  const onChangeCustomer = values => {
    setCustomers(values);
    setCustCodes(values.map(cust => cust.value));
  };

  const onRefreshData = () => {
    fetchData();
  };

  const getSummaryText = () => {
    return (
      <div style={{ fontWeight: 'bold' }}>
        Total Plots: {countPlots} | Total Jobs: {jobsFiltered.length} | Total
        Frames: {countTotalFrames} | Loading Progress:{' '}
        <span style={{ color: '#5cb85c' }}>
          {countFramesLoaded} Loaded (Green)
        </span>{' '}
        /{' '}
        <span style={{ color: '#d9534f' }}>
          {countTotalFrames - countFramesLoaded} Not Loaded (Red)
        </span>
      </div>
    );
  };

  const countFrames = (data, isTotal) => {
    let result = 0;
    data.map(job => {
      job.frames.map(frame => {
        if (isTotal) {
          result += 1;
        } else {
          if (frame.maxStageId >= 75) {
            result += 1;
          }
        }
      });
    });
    return result;
  };

  const countEmNo = data => {
    let result = [];
    data.map(job => {
      if (!result.includes(job.emNo)) {
        result.push(job.emNo);
      }
    });
    return result.length;
  };

  const countPlots = useMemo(() => countEmNo(jobsFiltered), [jobsFiltered]);

  const countTotalFrames = useMemo(
    () => countFrames(jobsFiltered, true),
    [jobsFiltered]
  );

  const countFramesLoaded = useMemo(
    () => countFrames(jobsFiltered, false),
    [jobsFiltered]
  );

  const onShowDetail = item => {
    setIsShowDetail(true);
    setIsLoadingDetail(true);
    if (item.boxId > 0) {
      //show box detail
      getBoxDetail(item.boxId)
        .then(res => {
          if (res.success && res.data) {
            setItemDetail({
              ...res.data,
              ...item,
              isBox: true
            });
          }
          setIsLoadingDetail(false);
        })
        .catch(error => {
          swalToastError('Get Box Detail Failed!');
          setIsLoadingDetail(false);
        });
    } else {
      // show frame detail
      getFrameDetailByHeaderIdAndFrameId(item.headerId, item.frameId)
        .then(res => {
          if (res.success && res.data) {
            setItemDetail({
              ...res.data,
              ...item,
              isBox: false
            });
          }
          setIsLoadingDetail(false);
        })
        .catch(error => {
          swalToastError('Get Box Detail Failed!');
          setIsLoadingDetail(false);
        });
    }
  };

  const onClickWatchStillagePhotos = (e, stillageId) => {
    setStillagePhotos([]);
    setIsShowStillagePhotos(true);
    setIsLoadingStillagePhotos(true);
    if (stillageId > 0) {
      getPhotos(stillageId);
    }
  };

  const getPhotos = stillageId => {
    const photos = [];
    getStillgePhotos(stillageId, 'framestillagephotos.loadingonstillage').then(
      res => {
        if (res && res.data.length > 0) {
          res.data.map(photo => {
            photo.path = photo.path.replace('s3/eva-files/', '');
            photos.push(photo);
          });
        }
        setStillagePhotos(photos);
        setIsLoadingStillagePhotos(false);
      }
    );
  };

  const generateStillageNo = job => {
    return (
      job.stillageInfos &&
      job.stillageInfos.map((s, idx) => {
        return (
          <div
            key={idx}
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <div>
              <i style={{ fontWeight: 600 }}>
                <span>
                  <a
                    href={`https://eva.emplas.co.uk/Internal/Despatch/StillageTrackerDevicesMapSearch.aspx?stillageNo=${s.stillageNo}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {s.stillageNo}
                  </a>
                  {s.startTime
                    ? ` (${dayjs(s.startTime).format('DD/MM/YYYY hh:mm')})`
                    : ''}
                </span>
              </i>
            </div>
            <Button
              variant="primary"
              onClick={e => onClickWatchStillagePhotos(e, s.stillageId)}
            >
              Photos
            </Button>
          </div>
        );
      })
    );
  };

  return (
    <div>
      <div>
        <h5>Plotting Stillages Dashboard</h5>
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px'
          }}
        >
          <div style={{ marginRight: '20px' }}>
            <Form.Label style={{ marginRight: '10px' }}>
              Delivery Date
            </Form.Label>
            <DatePicker
              selected={deliveryDate}
              onChange={date => onChangeDeliveryDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control col-3"
              placeholderText="Select Date"
              calendarStartDay={1}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '20px'
            }}
          >
            <Form.Label style={{ marginRight: '10px' }}>Status</Form.Label>
            <MultiSelect
              isMulti={false}
              closeMenuOnSelect={true}
              classNamePrefix="react-select"
              value={statusOption}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minWidth: '200px'
                })
              }}
              onChange={onChangeStatus}
              options={optionStatus}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '20px'
            }}
          >
            <Form.Label style={{ marginRight: '10px' }}>Show Bay?</Form.Label>
            <MultiSelect
              isMulti={false}
              closeMenuOnSelect={true}
              classNamePrefix="react-select"
              value={isShowBayDefault}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minWidth: '200px'
                })
              }}
              onChange={onChangeShowBay}
              options={BayFilerOptions}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: 500 }}>
            <Form.Label style={{ marginRight: '10px' }}>Customer</Form.Label>
            <MultiSelect
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minWidth: '250px'
                })
              }}
              options={buildCustomerFilterOptions(jobs)}
              isMulti={true}
              onChange={onChangeCustomer}
              maxMenuHeight={200}
            />
          </div>
        </div>

        <div className="mb-2 d-flex justify-content-between">
          <div className="d-flex align-items-center gap-3">
            {getSummaryText()}
            <div>
              <Button onClick={onRefreshData} size="sm">
                Refresh
              </Button>
            </div>
            <div>
              Last Updated At: <b>{lastUpdatedAt}</b>
            </div>
          </div>
          <div className="d-flex gap-2">
            <div
              style={{
                backgroundColor: '#fab9b9',
                fontWeight: 'bold',
                fontSize: 12,
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Not RTL
            </div>
            <div
              style={{
                backgroundColor: '#ffffc5',
                fontWeight: 'bold',
                fontSize: 12,
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              RTL
            </div>
            <div
              style={{
                backgroundColor: '#abf7b1',
                fontWeight: 'bold',
                fontSize: 12,
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              On Stillage
            </div>
            <div
              style={{
                border: '5px dashed #00fdff',
                fontWeight: 'bold',
                fontSize: 12,
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              ODR
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <StationSpinner /> Loading...
          </div>
        ) : (
          <div>
            {jobsFiltered.length > 0 ? (
              jobsFiltered.map((job, idx) => (
                <Card key={idx} style={{ marginBottom: '25px' }}>
                  <Card.Body>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          marginBottom: '5px'
                        }}
                      >
                        <div>
                          <b>Job No.:</b>{' '}
                          <i style={{ fontWeight: 600 }}>{job.jobNo}</i>{' '}
                          <b>| </b>
                        </div>
                        <div>
                          <b>Ref:</b>{' '}
                          <i style={{ fontWeight: 600 }}>{job.reference}</i>{' '}
                          <b>| </b>
                        </div>
                        <div>
                          <b>Customer:</b>{' '}
                          <i style={{ fontWeight: 600 }}>{job.customerName}</i>{' '}
                          <b>| </b>
                        </div>
                        <div>
                          <b>Postcode:</b>{' '}
                          <i style={{ fontWeight: 600 }}>
                            {job.deliveryPostCode}
                          </i>{' '}
                          <b> | </b>
                        </div>
                        {job.stillageInfos && job.stillageInfos.length > 0 ? (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <b>Loaded On Stillage No.:</b>
                            {generateStillageNo(job)}
                          </div>
                        ) : (
                          <div>
                            <b>Can Be Loaded On:</b>{' '}
                            <i style={{ fontWeight: 600 }}>
                              {Math.ceil(
                                job.frames.length / MAXIMUMSTILLAEGFRAMES
                              )}{' '}
                              Stillage(s)
                            </i>
                          </div>
                        )}
                      </div>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                          }}
                        >
                          {job.frames &&
                            job.frames.length > 0 &&
                            job.frames.map((frame, ind) => (
                              <div
                                key={ind}
                                onClick={() => onShowDetail(frame)}
                                style={{
                                  cursor: 'pointer',
                                  width: '190px',
                                  margin: '5px 5px 0 0',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                  border: frame.isODR
                                    ? '5px dashed #00fdff'
                                    : '',
                                  backgroundColor:
                                    frame.maxStageId >= 75
                                      ? '#abf7b1'
                                      : frame.maxStageId == 71
                                      ? '#ffffc5'
                                      : '#fab9b9'
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      frame.maxStageId >= 75
                                        ? '#abf7b1'
                                        : frame.maxStageId == 71
                                        ? '#ffffc5'
                                        : '#fab9b9'
                                  }}
                                >
                                  <Image
                                    src={`${process.env.REACT_APP_API_HOST}/FrameImage/${job.jobNo}/${frame.winNo}`}
                                    width={50}
                                    height={70}
                                    style={{ objectFit: 'contain' }}
                                  />
                                </div>
                                <div style={{ fontSize: 12 }}>
                                  <div style={{ fontWeight: 600 }}>
                                    Frame No.: {frame.winNo}
                                  </div>
                                  <div style={{ fontWeight: 600 }}>
                                    Size: {frame.width} x {frame.height}
                                  </div>
                                  <div style={{ fontWeight: 600 }}>
                                    Weight: {frame.weight.toFixed(2)} KG
                                  </div>
                                  <div style={{ fontWeight: 600 }}>
                                    LOC: {frame.location}
                                  </div>
                                  <div style={{ fontWeight: 600 }}>
                                    {`${frame.maxStageName ?? ''} `}
                                    {frame.maxStageId === 75 &&
                                      `On Stillage No.: ${frame.stillageNo} `}
                                  </div>
                                </div>
                              </div>
                            ))}

                          {job.boxes &&
                            job.boxes.length > 0 &&
                            job.boxes.map((box, ind) => (
                              <div
                                key={ind}
                                onClick={() => onShowDetail(box)}
                                style={{
                                  cursor: 'pointer',
                                  width: '190px',
                                  margin: '5px 5px 0 0',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                  backgroundColor:
                                    box.maxStageId >= 75
                                      ? '#abf7b1'
                                      : box.maxStageId == 71
                                      ? '#ffffc5'
                                      : '#fab9b9'
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: '5px',
                                    backgroundColor:
                                      box.maxStageId >= 75
                                        ? '#abf7b1'
                                        : box.maxStageId == 71
                                        ? '#ffffc5'
                                        : '#fab9b9'
                                  }}
                                >
                                  <Image
                                    src={
                                      box.boxType === 'Ancillary'
                                        ? AncillaryBoxImage
                                        : BoxImage
                                    }
                                    width={50}
                                    height={70}
                                    style={{ objectFit: 'contain' }}
                                  />
                                </div>
                                <div style={{ fontSize: 12 }}>
                                  <div style={{ fontWeight: 600 }}>
                                    Box ID.: {box.boxId}
                                  </div>
                                  <div style={{ fontWeight: 600 }}>
                                    Weight: {box.weight.toFixed(2)} KG
                                  </div>
                                  <div style={{ fontWeight: 600 }}>
                                    Type: {box.boxType}
                                  </div>
                                  <div style={{ fontWeight: 600 }}>
                                    {box.maxStageName}{' '}
                                    {box.maxStageId === 75 &&
                                      `On Stillage No.: ${box.stillageNo}`}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <div style={{ textAlign: 'center' }}>No Jobs Found</div>
            )}
          </div>
        )}
      </div>

      {/* MODAL */}
      <Modal show={isShowDetail} size="xl">
        <Modal.Header>
          <h5>Item Detail</h5>
        </Modal.Header>
        <Modal.Body>
          {isloadingDetail ? (
            <Spinner />
          ) : (
            <div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: itemDetail.odrDetails ? '1fr 1fr' : '1fr'
                }}
              >
                <div style={{ padding: 0, margin: 0 }}>
                  {itemDetail.boxId && itemDetail.boxId > 0 && (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.7fr 1fr',
                        gap: '10px',
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>BoxID:</div>
                      <div className="frame-detail-body__text-value">
                        {itemDetail.boxId}
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '0.7fr 1fr',
                      gap: '10px',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    <div style={{ textAlign: 'right' }}>Job No:</div>
                    <div className="frame-detail-body__text-value">
                      {itemDetail.jobNo}
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '0.7fr 1fr',
                      gap: '10px',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    <div style={{ textAlign: 'right' }}>Customer:</div>
                    <div className="frame-detail-body__text-value">
                      {itemDetail.mainName || itemDetail.customer}
                      <Badge bg="danger">{itemDetail.priorityType}</Badge>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '0.7fr 1fr',
                      gap: '10px',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    <div style={{ textAlign: 'right' }}>Delivery Date:</div>
                    <div className="frame-detail-body__text-value">
                      {itemDetail.requiredDate
                        ? dayjs(itemDetail.requiredDate).format('DD/MM/YYYY')
                        : ''}
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '0.7fr 1fr',
                      gap: '10px',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    <div style={{ textAlign: 'right' }}>Weight:</div>
                    <div className="frame-detail-body__text-value">
                      {itemDetail.weight?.toFixed(2)} KG
                    </div>
                  </div>
                  {itemDetail.boxId && itemDetail.boxId > 0 ? (
                    <>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>Box Type:</div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.boxTypeName}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>Win No:</div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.winNo}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>Size:</div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.width} x {itemDetail.height}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>Drainage:</div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.drainageDesc}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>Group:</div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.groupDesc}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>Finish 1:</div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.finishDesc}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>Finish 2:</div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.finish2Desc}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold',
                          color: '#d9534f'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>
                          Special Instructions:
                        </div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.instructions}
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '0.7fr 1fr',
                      gap: '10px',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    <div style={{ textAlign: 'right' }}>Stage:</div>
                    <div
                      className="frame-detail-body__text-value"
                      style={{
                        width: 'fit-content',
                        backgroundColor:
                          itemDetail.maxStageId >= 75
                            ? '#abf7b1'
                            : itemDetail.maxStageId == 71
                            ? '#ffffc5'
                            : '#fab9b9'
                      }}
                    >
                      {itemDetail.maxStageName}{' '}
                      {itemDetail.maxStageId === 75
                        ? 'On Stillage No.: ' + itemDetail.stillageNo
                        : ''}
                    </div>
                  </div>
                </div>

                {itemDetail.odrDetails && (
                  <div
                    style={{ padding: 0, margin: 0, border: '1px solid red' }}
                  >
                    <h5 style={{ marginLeft: '5px' }}> ODR Details: </h5>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.7fr 1fr',
                        gap: '10px',
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>
                        Original Job No.:
                      </div>
                      <div className="frame-detail-body__text-value">
                        {itemDetail.odrDetails?.originalJobNo}
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.7fr 1fr',
                        gap: '10px',
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>ODR Job No.:</div>
                      <div className="frame-detail-body__text-value">
                        {itemDetail.odrDetails?.odrJobNo}
                      </div>
                    </div>

                    {!dayjs(
                      itemDetail.odrDetails?.odrOriginalDeliveryDate
                    ).isSame(
                      dayjs(new Date('1901-01-01T00:00:00')),
                      'year' || !itemDetail.odrDetails?.odrOriginalDeliveryDate
                    ) && (
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>
                          ODR Original Delivery Date:
                        </div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.odrDetails?.odrOriginalDeliveryDate
                            ? dayjs(
                                itemDetail.odrDetails?.odrOriginalDeliveryDate
                              ).format('DD/MM/YYYY')
                            : ''}
                        </div>
                      </div>
                    )}

                    {!dayjs(itemDetail.odrDetails?.odrJobDeliveryDate).isSame(
                      dayjs(new Date('1901-01-01T00:00:00')),
                      'year' || !itemDetail.odrDetails?.odrJobDeliveryDate
                    ) && (
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.7fr 1fr',
                          gap: '10px',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>
                          ODR Delivery Date:
                        </div>
                        <div className="frame-detail-body__text-value">
                          {itemDetail.odrDetails?.odrJobDeliveryDate
                            ? dayjs(
                                itemDetail.odrDetails?.odrJobDeliveryDate
                              ).format('DD/MM/YYYY')
                            : ''}
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.7fr 1fr',
                        gap: '10px',
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>
                        Details Of Deficiency:
                      </div>
                      <div className="frame-detail-body__text-value">
                        {itemDetail.odrDetails?.odrDetailsOfDeficiency}
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.7fr 1fr',
                        gap: '10px',
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>
                        Reason For Shortage:
                      </div>
                      <div className="frame-detail-body__text-value">
                        {itemDetail.odrDetails?.odrReasonForShortage}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.7fr 1fr',
                        gap: '10px',
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>Fault Code:</div>
                      <div className="frame-detail-body__text-value">
                        {itemDetail.odrDetails?.faultCode}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.7fr 1fr',
                        gap: '10px',
                        fontSize: 16,
                        fontWeight: 'bold'
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>Stage:</div>
                      <div className="frame-detail-body__text-value">
                        {itemDetail.odrDetails?.stage}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="thinnerRow">
                <Col style={{ textAlign: 'center' }}>
                  {itemDetail.boxId && itemDetail.boxId > 0 ? (
                    <>
                      <Image
                        src={
                          itemDetail.boxType === 'Ancillary'
                            ? AncillaryBoxImage
                            : BoxImage
                        }
                        width={300}
                        style={{ objectFit: 'contain' }}
                      />
                    </>
                  ) : (
                    <>
                      <Image
                        src={`${process.env.REACT_APP_API_HOST}/FrameImage/${itemDetail.jobNo}/${itemDetail.winNo}`}
                        width={350}
                        style={{
                          objectFit: 'contain',
                          maxHeight: 350,
                          maxWidth: 350
                        }}
                      />
                    </>
                  )}
                </Col>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsShowDetail(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isShowStillagePhotos} size="xl">
        <Modal.Header>
          <h5>Stillage Photos</h5>
        </Modal.Header>
        <Modal.Body>
          {isLoadingStillagePhotos ? (
            <Spinner />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                flexWrap: 'wrap',
                maxHeight: '80vh',
                overflow: 'auto'
              }}
            >
              {stillagePhotos.length > 0 ? (
                stillagePhotos.map((p, ind) => (
                  <Image
                    src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/eva-files?key=${p.path}`}
                    alt="photo"
                    width={200}
                    height={200}
                    key={ind}
                    style={{ objectFit: 'contain' }}
                  />
                ))
              ) : (
                <h4> No Photo Founds </h4>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsShowStillagePhotos(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
