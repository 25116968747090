import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import { getSashAllocator } from 'services/frameService';

const defaultConfig = {
  fontSize: 32,
  fontColour: 'red'
};

export default function SashAllocator({ barcode, colour, size }) {
  const [isLoading, setIsLoading] = useState(false);
  const [sashAllocator, setSashAllocator] = useState();
  const [fontSize, setFontSize] = useState(
    size ? size : defaultConfig.fontSize
  );
  const [fontColour, setFontColour] = useState(
    colour ? colour : defaultConfig.fontColour
  );

  useEffect(() => {
    setFontColour(colour);
    setFontSize(size);
  }, [colour, size]);

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getSashAllocator(barcode)
        .then(res => {
          setIsLoading(false);
          if (res.success && res.data) {
            setSashAllocator(res.data);
          }
        })
        .catch(error => {
          swalToastError('Assigned the Rack And Slot Number Failed!');
        });
    }
  }, [barcode]);

  return isLoading ? (
    <StationSpinner />
  ) : barcode ? (
    sashAllocator ? (
      <div
        style={{
          fontSize: fontSize,
          color: fontColour,
          fontWeight: 600
        }}
      >
        <div>
          <p>Store In</p>
        </div>
        <div>
          <span>Rack: </span>
          <span>{sashAllocator.rackNumber}</span>
        </div>
        <div>
          <span>Slot: </span>
          <span>{sashAllocator.slotNumber}</span>
        </div>
      </div>
    ) : (
      <h2 style={{ color: fontColour, fontSize: fontSize }}>
        No Open Slots Available
      </h2>
    )
  ) : (
    <h2> Please Scan Barcode</h2>
  );
}
