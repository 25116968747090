import { HotTable } from '@handsontable/react';
import AccessDenied from 'components/errors/AccessDenied';
import 'handsontable/dist/handsontable.full.min.css';
import { checkboxRenderer, textRenderer } from 'handsontable/renderers';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import { getStations, updateStations } from 'services/stationService';

const StationSettings = () => {
  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  const [stations, setStations] = useState([]);
  const hotRef = useRef(null);

  useEffect(() => {
    if (permissionId <= 0) {
      verifyPermission();
    }
    getStations()
      .then(res => {
        if (res.data) {
          setStations(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Stations Failed!.');
      });
  }, []);

  const verifyPermission = () => {
    let loggedData = JSON.parse(localStorage.getItem('login-data'));
    if (loggedData && loggedData.isLogged) {
      dispatch(getPermission(loggedData.permissionId));
    }
  };

  function timingExpiredRender(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    textRenderer.apply(this, arguments);
    const data = hotRef?.current?.hotInstance?.getData();
    td.style.borderTop = '1px #d8e2ef solid';
    td.style.borderBottom = 'none';
    td.style.borderRight = 'none';
    td.style.borderLeft = 'none';
    td.style.fontSize = '1rem';
    td.style.fontWeight = 400;
    td.style.fontFamily = '"Open Sans"';
    if (data && data[row][3]) {
      td.style.backgroundColor = 'rgba(59, 59, 59, 0.3)';
    }
  }

  function cellRender(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.fontSize = '1rem';
    td.style.fontWeight = 400;
    td.style.fontFamily =
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
    td.style.backgroundColor = '#00000000';
    td.style.borderTop = '1px #d8e2ef solid ';
    td.style.borderBottom = 'none';
    td.style.borderRight = 'none';
    td.style.borderLeft = 'none';
  }

  function renderCheckbox(instance, td, row, col, prop, value, cellProperties) {
    checkboxRenderer.apply(this, arguments);
    td.style.borderTop = '1px #d8e2ef solid ';
    td.style.borderBottom = 'none';
    td.style.borderRight = 'none';
    td.style.borderLeft = 'none';
  }

  const onSave = () => {
    const hot = hotRef.current.hotInstance;
    const data = hot.getSourceData();

    updateStations(data)
      .then(res => {
        if (res.data) {
          swalToastSuccess('Updated stations Successful');
        }
      })
      .catch(error => {
        swalToastError('Failed to update stations');
      });
  };

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    stations.length > 0 && (
      <>
        <HotTable
          data={stations}
          ref={hotRef}
          className="htCenter htMiddle mb-3"
          fixedColumnsStart={1}
          rowHeights={50}
          colHeaders={colIndex => {
            let html = `<div style="font-size: 1rem; font-weight: 600; font-family: 'Open Sans'; height: 49px; display: flex; align-items: center;">`;
            switch (colIndex) {
              case 1:
                html += 'Station Name';
                break;
              case 2:
                html += 'Station Path';
                break;
              case 3:
                html += 'Session Expire?';
                break;
              case 4:
                html += 'Session Expired In (min)';
                break;
              case 5:
                html += 'Enable UID Login';
                break;
              case 6:
                html += 'Allow Typing Job And Stillage Number';
                break;
              case 7:
                html += 'Print Frame Only';
                break;
            }
            html += `</div>`;
            return html;
          }}
          columns={[
            { data: 'id', readOnly: true },
            { data: 'stationName', readOnly: true },
            { data: 'stationPath', readOnly: true },
            {
              data: 'disabledSessionExpired',
              type: 'checkbox'
            },
            {
              data: 'minutesSessionExpired',
              type: 'numeric'
            },
            {
              data: 'enableUidLogin',
              type: 'checkbox'
            },
            {
              data: 'enableScanByJobNo',
              type: 'checkbox'
            },
            {
              data: 'isPrintFrameOnly',
              type: 'checkbox'
            }
          ]}
          hiddenColumns={{
            columns: [0],
            indicators: true
          }}
          width="100%"
          height="auto"
          stretchH="all"
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          cells={function (row, col) {
            const cellProperties = {};
            const data = this.instance.getData();

            if (col === 3 || col === 5 || col === 6 || col === 7) {
              cellProperties.renderer = renderCheckbox;
            } else {
              cellProperties.renderer = cellRender;
            }

            if (col === 4 && data[row][3]) {
              cellProperties.readOnly = true;
            } else if (col === 4 && !data[row][3]) {
              cellProperties.readOnly = false;
            }

            if (col === 4) {
              cellProperties.renderer = timingExpiredRender;
            }

            return cellProperties;
          }}
        />
        <Button
          className="btn btn-sm btn-primary"
          onClick={(...args) => onSave(...args)}
        >
          Save
        </Button>
      </>
    )
  ) : (
    <AccessDenied></AccessDenied>
  );
};

export default StationSettings;
