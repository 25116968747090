import axiosHelper from 'helpers/axiosHepler';

const getFaultListByProductType = productType => {
  const url = `/QC/fault-list?productType=${productType}`;
  return axiosHelper.get(url);
};
``;
const getRejectLog = () => {
  const url = `/QC/reject-log`;
  return axiosHelper.get(url);
};

const getRejectLogs = (userId, pageLength) => {
  const url = `/QC/reject-logs?userId=${userId}&pageLength=${pageLength}`;
  return axiosHelper.get(url);
};

const addRejectLog = logItem => {
  const url = `/QC`;
  const data = logItem;
  return axiosHelper.post(url, data);
};

const addAlertLog = alertItem => {
  const url = `/QC/reject-alert`;
  const data = alertItem;
  return axiosHelper.post(url, data);
};

const getFaultAreas = () => {
  const url = `/QC/fault-areas`;
  return axiosHelper.get(url);
};

const getDecisions = () => {
  const url = `/QC/fault-decisions`;
  return axiosHelper.get(url);
};

const addDecision = decision => {
  const url = `/QC/fault-decision`;
  const data = { decision };
  return axiosHelper.post(url, data);
};

const getAncillIntoPack = payload => {
  const url = `/EvaScanStation/ancill-pack/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getWeledCillByBarcode = barcode => {
  const url = `/QC/welded-cill?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const addAncillChecking = payload => {
  const url = `/QC/ancill-checking`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const addWeldedCillChecking = weldedCillCheckingRequest => {
  const url = `/QC/welded-cill-checking`;
  const data = weldedCillCheckingRequest;
  return axiosHelper.post(url, data);
};

const startWeledCill = payload => {
  const url = `/EvaScanStation/welded-cill/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const startGlass = payload => {
  const url = `/EvaScanStation/glass/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const startHardware = payload => {
  const url = `/EvaScanStation/hardware/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getGlassByBarcode = barcode => {
  const url = `/QC/glass?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const addGlassChecking = glassCheckingRequests => {
  const url = `/QC/glass-checking`;
  const data = glassCheckingRequests;
  return axiosHelper.post(url, data);
};

const startStillage = payload => {
  const url = `/EvaScanStation/stillage/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getStillageByBarcode = barcode => {
  const url = `/QC/stillage?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const addStillageChecking = stillageCheckingRequest => {
  const url = `/QC/stillage-checking`;
  const data = stillageCheckingRequest;
  return axiosHelper.post(url, data);
};

const addHardwaresChecking = hardwareCheckings => {
  const url = `/QC/hardware-checking`;
  const data = hardwareCheckings;
  return axiosHelper.post(url, data);
};

const getOdrProcessed = payload => {
  const url = `/EvaScanStation/odr/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const addODRChecking = payload => {
  const url = `/QC/odr-checking`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getProductAllocations = () => {
  const url = `/EvaScanStation/products-allocation`;
  return axiosHelper.get(url);
};

export {
  addAlertLog,
  addAncillChecking,
  addDecision,
  addGlassChecking,
  addHardwaresChecking,
  addODRChecking,
  addRejectLog,
  addStillageChecking,
  addWeldedCillChecking,
  getAncillIntoPack,
  getDecisions,
  getFaultAreas,
  getFaultListByProductType,
  getGlassByBarcode,
  getOdrProcessed,
  getProductAllocations,
  getRejectLog,
  getRejectLogs,
  getStillageByBarcode,
  getWeledCillByBarcode,
  startGlass,
  startHardware,
  startStillage,
  startWeledCill
};
