import axiosHelper from 'helpers/axiosHepler';

const startScanFrame = payload => {
  const url = `/EvaScanStation/frame/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const startScanFrameV2 = payload => {
  const url = `/EvaScanStation/frame-v2/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const completeScanFrame = payload => {
  const url = `/EvaScanStation/frame/complete`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getFrameProductType = barcode => {
  const url = `/QC/product-type?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const getProductTypes = () => {
  const url = `/QC/product-types`;
  return axiosHelper.get(url);
};

const getProducts = () => {
  const url = `QC/products`;
  return axiosHelper.get(url);
};

const getFaultGroups = () => {
  const url = `/QC/fault-groups`;
  return axiosHelper.get(url);
};

const getFaultDescriptions = () => {
  const url = `/QC/fault-descriptions`;
  return axiosHelper.get(url);
};

const addFaultList = payload => {
  const url = `/QC/fault-list`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const submitNCCheckSheet = payload => {
  const url = `/QC/nc-check-sheets`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getFrameProductChecking = barcode => {
  const url = `/EvaScanStation/nc-product-checking?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const getHeaderIdAndFrameId = (jobNo, winNo) => {
  const url = `/EvaScanStation/barcode-info?jobNo=${jobNo}&winNo=${winNo}`;
  return axiosHelper.get(url);
};

const startScanFrameV3 = payload => {
  const url = `/EvaScanStation/frame-v3/start`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getSize = barcode => {
  const url = `/Panel/size?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const getSashAllocator = barcode => {
  const url = `/Panel/sash-allocator?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const getlabelsToPrint = (jobNo, winNo, stationId) => {
  const url = `/EvaScanStation/printing-labels?jobNo=${jobNo}&winNo=${winNo}&stationId=${stationId}`;
  return axiosHelper.get(url);
};

const getFrameDetailByHeaderIdAndFrameId = (headerId, frameId) => {
  const url = `/EvaScanStation/frame-detail?headerId=${headerId}&frameId=${frameId}`;
  return axiosHelper.get(url);
};

const getBoxDetail = boxId => {
  const url = `/EvaScanStation/box-detail?boxId=${boxId}`;
  return axiosHelper.get(url);
};

export {
  addFaultList,
  completeScanFrame,
  getBoxDetail,
  getFaultDescriptions,
  getFaultGroups,
  getFrameDetailByHeaderIdAndFrameId,
  getFrameProductChecking,
  getFrameProductType,
  getHeaderIdAndFrameId,
  getProductTypes,
  getProducts,
  getSashAllocator,
  getSize,
  getlabelsToPrint,
  startScanFrame,
  startScanFrameV2,
  startScanFrameV3,
  submitNCCheckSheet
};
