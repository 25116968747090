import axiosHelper from 'helpers/axiosHepler';

const getUserLogsByDate = (date, userId, stageId) => {
  const url = `/Tracking/user-logs?date=${date}&userId=${userId}&stageId=${stageId}`;
  return axiosHelper.get(url);
};

const getUserCuttingLogs = (date, userId, stageId) => {
  const url = `/Tracking/user-cutting-logs?date=${date}&userId=${userId}&stageId=${stageId}`;
  return axiosHelper.get(url);
};

const insertCuttingLog = payload => {
  const url = `/Tracking/cutting-log`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const insertTrackingBox = (trackingbox, ancilaryCutItems) => {
  const url = `/Tracking/insert-tracking-box`;
  const data = { trackingbox, ancilaryCutItems };
  return axiosHelper.post(url, data);
};

const insertListCuttingLog = items => {
  const url = `/Tracking/cutting-logs`;
  const data = items;
  return axiosHelper.post(url, data);
};

export {
  getUserCuttingLogs,
  getUserLogsByDate,
  insertCuttingLog,
  insertListCuttingLog,
  insertTrackingBox
};
