import ModalAuth from 'components/authentication/modal/ModalAuth';
import SplitConfirmMail from 'components/authentication/split/ConfirmMail';
import SplitForgetPassword from 'components/authentication/split/ForgetPassword';
import SplitLockScreen from 'components/authentication/split/LockScreen';
import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitPasswordReset from 'components/authentication/split/PasswordReset';
import SplitRegistration from 'components/authentication/split/Registration';
import { CloseButton } from 'components/common/Toast';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Starter from 'components/pages/Starter';
import HomeStation from 'components/station/[station]';
import FilterGroupsMangement from 'components/stations/FilterGroups';
import MachineSettings from 'components/stations/MachineSettings';
import MachineStartUpChecks from 'components/stations/MachineStartUpChecks';
import NewStation from 'components/stations/NewStation';
import PanelAction from 'components/stations/PanelActionManager';
import PartTranslation from 'components/stations/PartTranslation';
import QualityCheckSettings from 'components/stations/QualityCheckSettings';
import ScrewsMaxtrixes from 'components/stations/ScrewMatrixes';
import StationManager from 'components/stations/StationManager';
import StationQualityChecks from 'components/stations/StationQualityChecks';
import StationSettings from 'components/stations/StationSettings';
import StationsPCSetting from 'components/stations/StationsPCSetting';
import TrainingDocuments from 'components/stations/TrainingDocumentsManager';
import FrameStillageDashboard from 'components/stillages/PlottingStillagesDashboard';
import AppContext from 'context/Context';
import { swalToastWarning } from 'helpers/component-utils';
import is from 'is_js';
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getPCName } from 'services/stationService';
import Swal from 'sweetalert2';
import ErrorLayout from './ErrorLayout';
import MainLayout from './MainLayout';
import StationMainLayout from './StationMainLayout';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    getPCName()
      .then(res => {
        if (res && res.data && res.data.stationLink) {
          const pathName = window.location.pathname;
          if (pathName == '/') {
            navigate(`/station/${res.data.stationLink}`);
          } else if (
            pathName.split('/').length == 3 &&
            pathName.split('/')[1] == 'station' &&
            pathName.substring(pathName.lastIndexOf('/') + 1) !=
              res.data.stationLink
          ) {
            let timerInterval;
            Swal.fire({
              title: `Redirect to ${res.data.stationName} Station`,
              html: 'Redirect after <b></b> seconds.',
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector('b');
                timerInterval = setInterval(() => {
                  if (timer) {
                    timer.textContent = `${(
                      Swal.getTimerLeft() / 1000
                    ).toFixed()}`;
                  }
                }, 1000);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then(result => {
              const correctStationLink = `/station/${res.data.stationLink}`;
              const correctStationUrl = window.location.href.replace(
                window.location.pathname,
                correctStationLink
              );
              window.location.replace(correctStationUrl);
            });
          }
        }
      })
      .catch(error => {
        swalToastWarning(`Warning! Can"t Get Your PC Name`);
      });
  }, []);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        <Route path="authentication/split/login" element={<SplitLogin />} />
        <Route path="authentication/split/logout" element={<SplitLogout />} />
        <Route
          path="authentication/split/register"
          element={<SplitRegistration />}
        />
        <Route
          path="authentication/split/forgot-password"
          element={<SplitForgetPassword />}
        />
        <Route
          path="authentication/split/reset-password"
          element={<SplitPasswordReset />}
        />
        <Route
          path="authentication/split/confirm-mail"
          element={<SplitConfirmMail />}
        />
        <Route
          path="authentication/split/lock-screen"
          element={<SplitLockScreen />}
        />
        <Route element={<StationMainLayout />}>
          <Route path="station/:stationPath" element={<HomeStation />} />
        </Route>

        <Route path="/" element={<MainLayout />}>
          <Route path="stations/station-manager" element={<StationManager />} />
          <Route
            path="stations/station-setting"
            element={<StationSettings />}
          />
          <Route path="stations/new-station" element={<NewStation />} />
          <Route path="stations/panel-action" element={<PanelAction />} />
          <Route
            path="stations/training-documents"
            element={<TrainingDocuments />}
          />
          <Route
            path="stations/filter-groups"
            element={<FilterGroupsMangement />}
          />
          <Route
            path="stations/machine-settings"
            element={<MachineSettings />}
          />
          <Route
            path="stations/part-translation"
            element={<PartTranslation />}
          />

          <Route path="stations/pc-settings" element={<StationsPCSetting />} />

          <Route
            path="stations/screws-maxtrixes"
            element={<ScrewsMaxtrixes />}
          />
          <Route
            path="checks/machine-start-up-checks"
            element={<MachineStartUpChecks />}
          />
          <Route
            path="checks/quality-checks"
            element={<StationQualityChecks />}
          />
          <Route
            path="checks/quality-check-settings"
            element={<QualityCheckSettings />}
          />

          <Route
            path="/stillages/plotting-stillages"
            element={<FrameStillageDashboard />}
          />
          {/* <Route path="/stillages" element={<FrameStillagesLayout />}></Route> */}

          <Route path="pages/starter" element={<Starter />} />
          <Route path="authentication-modal" element={<ModalAuth />} />
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
