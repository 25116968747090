import axios from 'axios';
import axiosHelper from 'helpers/axiosHepler';

const getSashInfo = barcode => {
  const url = `/Panel/sash-location?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const clearSashLocation = payload => {
  const url = `/Panel/sash-location`;
  const data = payload;
  return axiosHelper.put(url, data);
};

const updateEnabledClearSash = (panelId, stationId, enabled) => {
  const url = `/EvaScanStation/enable-clear-sash?panelId=${panelId}&stationId=${stationId}&enabled=${enabled}`;
  return axiosHelper.patch(url);
};

const getEnabledClearSash = (panelId, stationId) => {
  const url = `/EvaScanStation/enable-clear-sash?panelId=${panelId}&stationId=${stationId}`;
  return axiosHelper.get(url);
};

export {
  getSashInfo,
  clearSashLocation,
  updateEnabledClearSash,
  getEnabledClearSash
};
