export const stationRoutes = {
  label: 'Scan Station Management',
  children: [
    {
      name: 'Stations Management',
      active: true,
      icon: 'tasks',
      children: [
        {
          name: 'New Station',
          to: '/stations/new-station',
          exact: true,
          active: true
        },
        {
          name: 'Stations',
          to: '/stations/station-manager',
          active: true
        },
        {
          name: 'Station Settings',
          to: '/stations/station-setting',
          active: true
        },
        {
          name: 'Panel Action',
          to: '/stations/panel-action',
          active: true
        },
        {
          name: 'Filter Groups',
          to: '/stations/filter-groups',
          active: true
        },
        {
          name: 'Part Translation',
          to: '/stations/part-translation',
          active: true
        },
        {
          name: 'Training Documents',
          to: '/stations/training-documents',
          active: true
        },
        {
          name: 'Machine Settings',
          to: '/stations/machine-settings',
          active: true
        },
        {
          name: 'Computer Settings',
          to: '/stations/pc-settings',
          active: true
        },
        {
          name: 'Hinge - Screws Matrixes',
          to: '/stations/screws-maxtrixes',
          active: true
        }
      ]
    },
    {
      name: 'Checks Management',
      active: true,
      icon: 'exclamation-triangle',
      children: [
        {
          name: 'Machine Start-Up Checks',
          to: '/checks/machine-start-up-checks',
          active: true
        },
        {
          name: 'Station Quality Checks',
          to: '/checks/quality-checks',
          active: true
        },
        {
          name: 'Quality Check Settings',
          to: '/checks/quality-check-settings'
        }
      ]
    }
  ]
};

export const StillageRoutes = {
  label: 'Dashboard',
  children: [
    {
      name: 'Frame Stillage',
      active: true,
      icon: 'trophy',
      children: [
        {
          name: 'Plotting Stillages dashboard',
          to: '/stillages/plotting-stillages',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export default [stationRoutes, StillageRoutes];
