import axios from 'axios';
import axiosHelper from 'helpers/axiosHepler';

const bucketName = process.env.REACT_APP_BUCKET_NAME;

const uploadFileToS3 = async (file, folderPrefix) => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/upload?bucketName=${bucketName}&prefix=${folderPrefix}`,
    method: 'POST',
    data: file
  });

  try {
    return {
      success: res.data.success,
      message: res.data.message,
      mapping: res.data.mapping
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const uploadMultiFilesToS3 = async (files, folderPrefix) => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/multi-upload?bucketName=${bucketName}&prefix=${folderPrefix}`,
    method: 'POST',
    data: files
  });

  try {
    return {
      success: res.data.success,
      message: res.data.message,
      mappings: res.data.mappings
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteS3File = async fileKey => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/${bucketName}?key=${fileKey}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data.StatusCode == 204,
      message: res.data.Message
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteMultipleS3Files = async fileKeys => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/multiple/${bucketName}`,
    method: 'DELETE',
    data: fileKeys
  });

  try {
    return {
      success: res.data.StatusCode == 204,
      message: res.data.Message
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getAttachmentByRecordId = (recordId, app) => {
  const url = `/attachment-management/attachments/${recordId}?app=${app}`;
  return axiosHelper.get(url);
};

const getAttachmentsByRecordId = (recordId, app) => {
  const url = `/attachment-management/attachments/multiple/${recordId}?app=${app}`;
  return axiosHelper.get(url);
};

const insertAttachmentRecord = item => {
  const url = `/attachment-management/attachments`;
  const data = item;
  return axiosHelper.post(url, data);
};

const insertMultiAttachmentRecords = attachments => {
  const url = `/attachment-management/attachments/multiple`;
  const data = attachments;
  return axiosHelper.post(url, data);
};

const deleteAttachmentRecord = id => {
  const url = `/attachment-management/attachments/${id}`;
  return axiosHelper.delete(url);
};

const deleteAttachmentsByRecordId = (recordId, app) => {
  const url = `/attachment-management/attachments/multiple/${recordId}?app=${app}`;
  return axiosHelper.delete(url);
};

const updateAttachmentImgDisplaySize = displaySize => {
  const url = `/attachment-management/attachments/${displaySize.attachmentId}/img-size?width=${displaySize.imgWidth}&height=${displaySize.imgHeight}`;
  return axiosHelper.patch(url);
};

const getAtachmentPathsByPcodes = pcodes => {
  const url = `/attachment-management/attachments/paths`;
  const data = pcodes;
  return axiosHelper.post(url, data);
};

export {
  deleteAttachmentRecord,
  deleteAttachmentsByRecordId,
  deleteMultipleS3Files,
  deleteS3File,
  getAtachmentPathsByPcodes,
  getAttachmentByRecordId,
  getAttachmentsByRecordId,
  insertAttachmentRecord,
  insertMultiAttachmentRecords,
  updateAttachmentImgDisplaySize,
  uploadFileToS3,
  uploadMultiFilesToS3
};
