import { React, useEffect } from 'react';
import { Table, Image } from 'react-bootstrap';
import { useState } from 'react';
import { getPartItemsV2 } from 'services/partService';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';

export default function PartsV4Widget(props) {
  let barcode = props.barcode;
  let groupName = props.groupName;

  const [partItems, setPartItems] = useState([]);
  const [diff, setDiff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [codeChanged, setCodeChanged] = useState(false);
  let partItemRows = [];

  useEffect(() => {
    if (barcode != 0) {
      setIsLoading(true);
      getPartItemsV2(barcode, groupName)
        .then(res => {
          if (res.data && res.success) {
            setPartItems(res.data?.partItems);
          }

          setIsLoading(false);
        })
        .catch(err => {
          swalToastError('Get Part Items Failed!');
        });
    } else {
      setPartItems([]);
    }
  }, [barcode]);

  if (partItems?.length > 0) {
    partItems.forEach(function (item, index) {
      let textDecorationCss = '';

      if (item.fontUnderline && item.fontStrikeOut) {
        textDecorationCss = 'underline line-through';
      } else {
        if (item.fontUnderline) textDecorationCss = 'underline';
        else if (item.fontStrikeOut) textDecorationCss = 'line-through';
      }

      partItemRows.push(
        <tr
          key={'row' + index}
          style={{
            color: item.fontColour,
            backgroundColor: item.bkColour,
            fontWeight: item.fontBold ? 'bold' : '',
            fontSize: item.fontSize,
            fontStyle: item.fontItalic ? 'italic' : '',
            textDecoration: textDecorationCss
          }}
        >
          <td>{item.qty}</td>
          <td>{item.pCode}</td>
          <td>{item.description}</td>
          <td>{item.typeDesc}</td>
        </tr>
      );
    });
  } else {
    partItemRows.push(
      <tr key={'row0'}>
        <td colSpan={4}>
          {props.groupName
            ? 'No records found.'
            : 'Please Select The Filter Group!'}
        </td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Qty</th>
            <th>P/Code</th>
            <th>Description</th>
            <th>Type description</th>
          </tr>
        </thead>
        <tbody>{partItemRows}</tbody>
      </Table>
    </>
  );
}
