import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getUserLogsByDate } from 'services/trackingService';

export default function UserLogWidget(props) {
  let station = props.station;
  let userId = props.userId;
  let realName = props.realName;
  const intervalTime = 30000;

  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let today = dayjs(); // get logs for today only
  let logRows = [];

  let isRendered = useRef(false);
  useEffect(() => {
    isRendered = true;

    if (userId > 0 && station.completedStage > 0) {
      loadLogsTable(today, userId, station.completedStage);

      const intervalCheck = setInterval(() => {
        loadLogsTable(today, userId, station.completedStage);
      }, intervalTime);

      return () => {
        isRendered = false;
        clearInterval(intervalCheck);
      };
    }
  }, [props.userId]);

  const loadLogsTable = (date, userId, stageId) => {
    setIsLoading(true);
    getUserLogsByDate(date, userId, stageId)
      .then(res => {
        if (res?.success && res.data) {
          setLogData(res.data);
        } else {
          swalToastError('Could not get user logs. Please try again.');
        }

        setIsLoading(false);
      })
      .catch(err => {
        swalToastError('Error. Something went wrong.');
      });
  };

  if (logData && logData.length > 0) {
    logRows = [];
    logData.forEach(function (item, index) {
      const start = dayjs(item.startTime);
      const timeString = start.format('HH:mm');
      logRows.push(
        <tr
          key={'row' + index}
          // className={logRowClass}
          // style={{ color: 'white' }}y
        >
          <td>{item.stageName}</td>
          <td>{timeString}</td>
          <td>{item.jobNo}</td>
          <td>{item.winNo}</td>
          <td>{item.square}</td>
          <td>{item.productType}</td>
        </tr>
      );
    });
  } else {
    logRows.push(
      <tr key={'row0'}>
        <td colSpan={6}>No log found.</td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <h6>Hi {realName}</h6>
      <h6>View logs for {today.format('DD/MM/YYYY')}</h6>
      <h6>Update @ {dayjs().format('DD/MM/YYYY HH:mm:ss')}</h6>
      <Table className="h6 table-sm" style={{ fontSize: '8px' }}>
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Stage</th>
            <th>Start</th>
            <th>Job No.</th>
            <th>
              Win No.<br></br>/Scan Count
            </th>
            <th>Sq</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td>
              <b>Total:</b>
            </td>
            <td>
              <b>{logData.length}</b>
            </td>
            <td>
              <b>
                {logRows.length > 0
                  ? logData.reduce((ac, c) => ac + c.square, 0)
                  : 0}
              </b>
            </td>
            <td></td>
          </tr>
          {logRows}
        </tbody>
      </Table>
    </>
  );
}
