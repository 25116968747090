import axiosHelper from '../helpers/axiosHepler';

const getStations = (search = '') => {
  const url = `/EvaScanStation?search=${search}`;
  return axiosHelper.get(url);
};

const getStationByPath = path => {
  const url = `/EvaScanStation/station?path=${path}`;
  return axiosHelper.get(url);
};

const getStages = () => {
  const url = `/EvaScanStation/stages`;
  return axiosHelper.get(url);
};

const getPanels = () => {
  const url = `/EvaScanStation/panels`;
  return axiosHelper.get(url);
};

const getPanelById = panelId => {
  const url = `/EvaScanStation/panel?panelId=${panelId}`;
  return axiosHelper.get(url);
};

const addStation = station => {
  const url = `/EvaScanStation/station`;
  const data = station;
  return axiosHelper.post(url, data);
};

const updateStation = station => {
  const url = `/EvaScanStation/station`;
  const data = station;
  return axiosHelper.patch(url, data);
};

const getStationLayout = stationId => {
  const url = `/EvaScanStation/layout?stationId=${stationId}`;
  return axiosHelper.get(url);
};

const getScanningActions = () => {
  const url = `/EvaScanStation/scanning-actions`;
  return axiosHelper.get(url);
};

const updateStationLayout = (stationId, layout, mode) => {
  const url = `/EvaScanStation/layout?stationId=${stationId}&mode=${mode}`;
  const data = layout;
  return axiosHelper.patch(url, data);
};

const updatePanelLockMode = (panelId, mode) => {
  const url = `/EvaScanStation/panel-lock?panelId=${panelId}&mode=${mode}`;
  return axiosHelper.patch(url);
};

const updatePanelHeader = (panelSetupId, newData) => {
  const url = `/EvaScanStation/panel-header?panelSetupId=${panelSetupId}`;
  return axiosHelper.patch(url, newData);
};

const updatePanelSettings = (
  panelSetupId,
  title,
  filterGroupName,
  colour,
  isShowSize,
  enabledMoveStage
) => {
  const url = `/EvaScanStation/panel-settings?panelSetupId=${panelSetupId}&title=${title}&filterGroup=${filterGroupName}&colour=${colour}&isShowSize=${isShowSize}&enabledMoveStage=${enabledMoveStage}`;

  return axiosHelper.patch(url);
};

const updateScanningAction = (actionId, panelId) => {
  const url = `/EvaScanStation/scanning-action?actionId=${actionId}&panelId=${panelId}`;
  return axiosHelper.patch(url);
};

const updateStations = stations => {
  const url = `/EvaScanStation`;
  const data = stations;
  return axiosHelper.put(url, data);
};

const getPCName = () => {
  const url = `/EvaScanStation/pc-name`;
  return axiosHelper.get(url);
};

const getStationPCSettings = search => {
  const url = `/EvaScanStation/station-pc-seting?search=${search}`;
  return axiosHelper.get(url);
};

const addStationPCSettings = PCSetting => {
  const url = `/EvaScanStation/station-pc-seting`;
  const data = PCSetting;
  return axiosHelper.post(url, data);
};

const editStationPCSettings = (id, newPCSetting) => {
  const url = `/EvaScanStation/station-pc-seting?id=${id}`;
  const data = newPCSetting;
  return axiosHelper.put(url, data);
};

const deleteStationPCSettings = id => {
  const url = `/EvaScanStation/station-pc-seting/${id}`;
  return axiosHelper.delete(url);
};

export {
  addStation,
  addStationPCSettings,
  deleteStationPCSettings,
  editStationPCSettings,
  getPCName,
  getPanelById,
  getPanels,
  getScanningActions,
  getStages,
  getStationByPath,
  getStationLayout,
  getStationPCSettings,
  getStations,
  updatePanelHeader,
  updatePanelLockMode,
  updatePanelSettings,
  updateScanningAction,
  updateStation,
  updateStationLayout,
  updateStations
};
