import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getStationFilterGroups,
  getStationPartMatchFields
} from 'redux/slices/stationSlice';
import 'react-toastify/dist/ReactToastify.css';
import {
  Form,
  Card,
  ListGroup,
  Tab,
  Tabs,
  Modal,
  Button,
  Col
} from 'react-bootstrap';
import FilterItemsTable from 'components/station/FilterItemsTable';
import {
  getGroupExcludeOption,
  getMergedFilterGroups,
  insertFilterItemSetup,
  updateGroupExcludeOption,
  updateGroupMatchField,
  updateMergeFilterGroup
} from 'services/filterService';
import ProductImageTable from 'components/station/ProductImageTable';
import { colourData } from 'data/stations/colourData';
import { getPermission, getPermissions } from 'redux/slices/permissionSlice';
import AccessDenied from 'components/errors/AccessDenied';
import { getMatchFieldByGroup } from 'services/filterService';
import {
  swalToast,
  swalToastError,
  swalToastSuccess
} from 'helpers/component-utils';

export default function FilterGroupsMangement(props) {
  const dispatch = useDispatch();

  const permissionId = useSelector(store => store.permission.permissionId);

  let filterGroupOptions = [];
  let mergeFilterGroupOptions = [];
  let matchFieldOptions = [];

  const [selectedGroup, setSelectedGroup] = useState('');
  const [matchField, setMatchField] = useState(0);
  const [mergedGroups, setMergedGroups] = useState([]);
  const [isExcluded, setIsExcluded] = useState(false);
  const [key, setKey] = useState(Date());
  const [imgTableKey, setImgTableKey] = useState(Date());
  const [modalAddNewShow, setModalAddNewShow] = useState(false);
  const [newFilter, setNewFilter] = useState({
    filterGroupName: '',
    filterName: '',
    bgColour: '#FFFFFF',
    fontColour: '#000000',
    fontSize: 14,
    fontBold: false,
    fontUnderlin: false,
    fontItalic: false,
    fontStroke: false
  });
  const [searchFilterGroup, setSearchFilterGroup] = useState('');
  const [searchMergeFilterGroup, setSearchMergeFilterGroup] = useState('');

  let colourOptions = [];

  useEffect(() => {
    dispatch(getStationFilterGroups());
    dispatch(getStationPartMatchFields());

    if (permissionId <= 0) {
      let loggedData = JSON.parse(localStorage.getItem('login-data'));
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      getGroupExcludeOption(selectedGroup)
        .then(res => {
          if (res.success && res.data) {
            setIsExcluded(res?.data);
          }
        })
        .catch(error => {
          swalToastError('Get Group Excluded Option Failed!');
        });
    }
  }, [selectedGroup]);

  const handleSelectGroup = group => {
    setSelectedGroup(group);

    setNewFilter(prevState => ({
      ...prevState,
      filterGroupName: group
    }));

    getMatchFieldByGroup(group)
      .then(res => {
        if (res.success && res.data) {
          setMatchField(res?.data?.id ?? 0);
        }
      })
      .catch(error => {
        swalToastError('Get Filter Group Match Field Failed!');
      });
  };

  const openAddFilterModal = () => {
    setModalAddNewShow(true);
  };

  const handleInputChange = e => {
    setNewFilter({ ...newFilter, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = e => {
    setNewFilter({ ...newFilter, [e.target.name]: e.target.checked });
  };

  const handleChangeMatchField = e => {
    let payload = { item1: selectedGroup, item2: e.target.value };
    setMatchField(e.target.value);

    if (e.target.value > 0) {
      updateGroupMatchField(payload)
        .then(res => {
          //console.log(res);
          if (res.success && res.data) {
            swalToastSuccess(`Match field has been updated`);
          } else {
            swalToastError(`Could not update match field`);
          }
        })
        .catch(err => {
          console.log(err);
          swalToastError(`Error. Something went wrong`);
        });
    }
  };

  const handleSubmitNewFilter = e => {
    e.preventDefault();
    e.stopPropagation();

    insertFilterItemSetup(newFilter)
      .then(res => {
        if (res.success && res.data) {
          setModalAddNewShow(false);
          // clear values
          swalToastSuccess('New Filter has been added');

          onDataChanged();
        } else {
          swalToastError(`Error. Please try again`);
        }
      })
      .catch(err => {
        console.log(err);
        swalToastError(`Error. Please try again`);
      });
  };

  const handleMergeGroupsChange = e => {
    const { value, checked } = e.target;

    if (checked) {
      setMergedGroups(prev => [...prev, value]);
      updateMergeFilterGroup(selectedGroup, value, 'merge')
        .then(res => {
          if (res?.success && res.data) {
            swalToastSuccess(`Merged`);
          }
        })
        .catch(error => {
          swalToastError('Merge Failed!');
        });
    } else {
      setMergedGroups(prev => prev.filter(x => x !== value));
      updateMergeFilterGroup(selectedGroup, value, 'un-merge')
        .then(res => {
          if (res?.success && res.data) {
            swalToastSuccess('Unmerged');
          }
        })
        .catch(error => {
          swalToastError('Unmerge Failed');
        });
    }
  };

  const handleExcludeMatchedItemsCheck = e => {
    // console.log(e.target.checked);
    setIsExcluded(e.target.checked);

    let payload = { item1: selectedGroup, item2: e.target.checked };

    updateGroupExcludeOption(payload)
      .then(res => {
        if (res?.success && res.data) {
          swalToastSuccess(`Exclude option has been updated`);
        } else {
          swalToastError(`Could not update Exclude option`);
        }
      })
      .catch(err => {
        swalToastError(`Error. Something went wrong`);
      });
  };

  const onDataChanged = () => {
    setKey(Date());
    setImgTableKey(Date());
  };

  const filterGroups = useSelector(store => store.station.filterGroups);
  const partMatchFields = useSelector(store => store.station.partMatchFields);

  const sortFilterGroup = data => {
    const result = [...data];
    return result.sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  };

  const handleSearchChange = event => {
    setSearchFilterGroup(event.target.value);
  };

  const handleSearchMergeFilterGroupChange = event => {
    setSearchMergeFilterGroup(event.target.value);
  };

  useEffect(() => {
    if (selectedGroup) {
      getMergedFilterGroups(selectedGroup)
        .then(res => {
          if (res.success && res.data) {
            setMergedGroups(res.data);
          }
        })
        .catch(error => {
          swalToastError('Get Merged Filter Groups Failed!');
        });
    }
  }, [selectedGroup]);

  const generatefilterGroupOptions = options => {
    let filterGroupSorted = sortFilterGroup(options);
    filterGroupSorted = filterGroupSorted.filter(item =>
      item.toLowerCase().includes(searchFilterGroup.toLowerCase())
    );
    return filterGroupSorted.map((item, index) => {
      return (
        <ListGroup.Item
          key={'group' + (index + 1)}
          action
          href={`#${item}`}
          onClick={() => handleSelectGroup(item)}
        >
          {item}
        </ListGroup.Item>
      );
    });
  };

  const generateMergeFilterGroupOptions = options => {
    let filterMergeGroupSorted = sortFilterGroup(options);
    filterMergeGroupSorted = filterMergeGroupSorted.filter(
      item =>
        item.toLowerCase().includes(searchMergeFilterGroup.toLowerCase()) &&
        item != selectedGroup
    );
    return filterMergeGroupSorted.map((item, index) => {
      return (
        <Form.Check
          type={'checkbox'}
          id={`default-${item}`}
          label={item}
          key={'mGroup' + (index + 1)}
          value={item}
          checked={mergedGroups.includes(item)}
          onChange={handleMergeGroupsChange}
        />
      );
    });
  };

  if (colourData.length > 0) {
    colourData.forEach((item, index) => {
      colourOptions.push(
        <option
          key={`cl${index}`}
          value={item.value}
          style={{ backgroundColor: item.value }}
        >
          {item.name}
        </option>
      );
    });
  } else {
    colourOptions.push(
      <option key={`cl0`} value={''}>
        No colour found
      </option>
    );
  }

  if (partMatchFields.length > 0) {
    matchFieldOptions.push(
      <option key={`mf0`} value={0}>
        Please select a match field
      </option>
    );
    partMatchFields.forEach((item, index) => {
      matchFieldOptions.push(
        <option key={`mf${index + 1}`} value={item.id}>
          {item.matchFieldName}
        </option>
      );
    });
  } else {
    matchFieldOptions.push(
      <option key={`mf0`} value={0}>
        No match field found
      </option>
    );
  }
  // console.log('permissions', permissions.includes('ft.admin'));

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <div>
      <div className="g-3 mt-1 row">
        <div className="col-lg-3">
          <Card>
            <Card.Header>
              <h5 className="mb-0 text-center">Filters Groups</h5>
            </Card.Header>
            <Card.Body>
              <Form.Control
                className="mb-3"
                type="text"
                placeholder="Search filter group"
                value={searchFilterGroup}
                onChange={handleSearchChange}
              />
              <ListGroup
                // defaultActiveKey="#group0"
                style={{ maxHeight: '70vh', overflowY: 'scroll' }}
              >
                {filterGroups?.length > 0 ? (
                  generatefilterGroupOptions(filterGroups)
                ) : (
                  <ListGroup.Item key={'group'} action href={`#`}>
                    No filter group found.
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-9">
          <Card style={{ height: '100%' }}>
            <Card.Header>
              <h5 className="mb-0 text-center">Settings</h5>
            </Card.Header>
            {selectedGroup ? (
              <Card.Body>
                <Tabs>
                  <Tab eventKey="filterItems" title="Filter Items">
                    <div className="row pt-3">
                      <div className="col-lg-3">
                        <label className="form-label">Match Items With:</label>
                      </div>
                      <div className="col-lg-5">
                        <Form.Control
                          as="select"
                          name="scanningActionId"
                          value={matchField}
                          onChange={handleChangeMatchField}
                          required
                        >
                          {matchFieldOptions}
                        </Form.Control>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-lg-2">
                        <label className="form-label">Match Items:</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <FilterItemsTable
                          key={key}
                          group={selectedGroup}
                          onOpenAddFilterModal={openAddFilterModal}
                          onDataChanged={onDataChanged}
                        ></FilterItemsTable>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <Col className="col-lg-6">
                        <Form.Check
                          inline
                          label="Exclude Matched Items"
                          name="excludeMatchedItems"
                          type="checkbox"
                          id={`checkboxExclude`}
                          checked={isExcluded}
                          onChange={handleExcludeMatchedItemsCheck}
                        />
                      </Col>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="d-flex align-items-center flex-wrap mb-3 gap-3">
                        <label className="form-label mb-0">
                          Merge with filter groups:
                        </label>
                        <Form.Control
                          className="w-50"
                          type="text"
                          placeholder="Search merge filter group"
                          value={searchMergeFilterGroup}
                          onChange={handleSearchMergeFilterGroupChange}
                        />
                      </div>
                      <Form
                        className="d-grid"
                        style={{
                          gap: '1rem',
                          gridTemplateColumns:
                            'repeat(auto-fill, minmax(200px, 1fr))',
                          maxHeight: '225px',
                          overflowY: 'auto'
                        }}
                      >
                        {generateMergeFilterGroupOptions(filterGroups)}
                      </Form>
                    </div>
                  </Tab>
                  <Tab eventKey="pCodeImage" title="Product Image">
                    <div className="row">
                      <div className="col-lg-12">
                        <ProductImageTable
                          key={imgTableKey}
                          group={selectedGroup}
                        ></ProductImageTable>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </Card.Body>
            ) : (
              <Card.Body>Please select a group</Card.Body>
            )}
          </Card>
        </div>
      </div>
      <Modal
        show={modalAddNewShow}
        dialogClassName="modal-60w"
        size="lg"
        animation={false}
        autoFocus={true}
      >
        <Modal.Header>
          <Modal.Title>
            {/* <div className="row">
            <div className="col-lg-12">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="4x"
              ></FontAwesomeIcon>
            </div>
          </div> */}
            <h3>New Filter Item</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off" noValidate onSubmit={handleSubmitNewFilter}>
            <Form.Group className="mb-3" controlId="filterName">
              <Form.Label>Filter Group</Form.Label>
              <Form.Control
                name="filterGroup"
                type="text"
                readOnly={true}
                value={selectedGroup}
                onChange={e => {}}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="filterName">
              <Form.Label>Filter Name</Form.Label>
              <Form.Control
                name="filterName"
                type="text"
                autoFocus={true}
                required
                value={newFilter.filterName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="bgColour">
              <Form.Label>Background Colour:</Form.Label>
              <Form.Control
                as="select"
                name="bgColour"
                drop="down"
                value={newFilter.bgColour}
                onChange={handleInputChange}
              >
                {colourOptions}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="fontColour">
              <Form.Label>Font Colour:</Form.Label>
              <Form.Control
                as="select"
                name="fontColour"
                drop="down"
                value={newFilter.fontColour}
                onChange={handleInputChange}
              >
                {colourOptions}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="fontSize">
              <Form.Label>Font Size</Form.Label>
              <Form.Control
                name="fontSize"
                type="number"
                required
                value={newFilter.fontSize}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Font Settings:</Form.Label>
              <div key={`inline-checkboxes`} className="mb-3">
                <Form.Check
                  inline
                  label="Bold"
                  name="fontBold"
                  type="checkbox"
                  id={`inline-checkbox-1`}
                  checked={newFilter.fontBold}
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  inline
                  label="Italic"
                  name="fontItalic"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  checked={newFilter.fontItalic}
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  inline
                  label="Underline"
                  name="fontUnderline"
                  type="checkbox"
                  id={`inline-checkbox-3`}
                  checked={newFilter.fontUnderline}
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  inline
                  label="Stroke"
                  name="fontStroke"
                  type="checkbox"
                  id={`inline-checkbox-3`}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Button variant="primary" type="submit">
                Save
              </Button>{' '}
              <Button
                variant="secondary"
                onClick={() => {
                  setModalAddNewShow(false);
                }}
              >
                Close
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    <AccessDenied></AccessDenied>
  );
}
