import axios from 'axios';
import axiosHelper from 'helpers/axiosHepler';

const getPartItems = (barcode, group) => {
  const url = `/Panel/part-items?barcode=${barcode}&filterGroup=${group}`;
  return axiosHelper.get(url);
};

const getPartItemsV2 = (barcode, group, includeImages = false) => {
  const url = `/Panel/part-items-v2?barcode=${barcode}&filterGroup=${group}&includeImages=${includeImages}`;
  return axiosHelper.get(url);
};

const getPartItemsV3 = (barcode, group) => {
  const url = `/Panel/part-items-v3?barcode=${barcode}&filterGroup=${group}`;
  return axiosHelper.get(url);
};

const getPartItemsV4 = (barcode, group) => {
  const url = `/Panel/part-items-v4?barcode=${barcode}&filterGroup=${group}`;
  return axiosHelper.get(url);
};

const getPartItemsV5 = (barcode, group) => {
  const url = `/Panel/part-items-v5?barcode=${barcode}&filterGroup=${group}`;
  return axiosHelper.get(url);
};

const getGlassPartItems = (barcode, group) => {
  const url = `/Panel/glass-part-items?barcode=${barcode}&filterGroup=${group}`;
  return axiosHelper.get(url);
};

const getStraightItem = barcode => {
  const url = `/Panel/straight-warning?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const addPartsTranslate = partsTranslate => {
  const url = `/Panel/ss-description`;
  const data = partsTranslate;
  return axiosHelper.post(url, data);
};

const editPartsTranslate = (id, partsTranslate) => {
  const url = `/Panel/ss-description?id=${id}`;
  const data = partsTranslate;
  return axiosHelper.put(url, data);
};

const getPartsTranslate = search => {
  const url = `/Panel/ss-descriptions?search=${search}`;
  return axiosHelper.get(url);
};

const deletePartsTranslate = id => {
  const url = `/Panel/ss-description?id=${id}`;
  return axiosHelper.delete(url);
};

export {
  getGlassPartItems,
  getPartItems,
  getPartItemsV2,
  getPartItemsV3,
  getPartItemsV4,
  getPartItemsV5,
  getStraightItem,
  addPartsTranslate,
  editPartsTranslate,
  getPartsTranslate,
  deletePartsTranslate
};
