import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { measurables } from 'data/stations/widgetData';
import {
  swalToast,
  swalToastError,
  swalToastSuccess
} from 'helpers/component-utils';
import { getSize } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  ListGroup,
  Modal,
  Row,
  Table
} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import {
  deleteAttachmentRecord,
  deleteAttachmentsByRecordId,
  deleteMultipleS3Files,
  deleteS3File,
  insertMultiAttachmentRecords,
  uploadMultiFilesToS3
} from 'services/attachmentService';
import { getOptionsByKey } from 'services/optionListService';
import {
  createStationQc,
  createStationQcStep,
  deleteStationQc,
  deleteStationQcStep,
  deleteStationQcStepsByCheck,
  getQualityCheckById,
  getQualityCheckStepsByCheck,
  getQualityChecksByStation,
  updateStationQc,
  updateStationQcStep
} from 'services/stationQcService';
import { getStations } from 'services/stationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import AccessDenied from 'components/errors/AccessDenied';

export default function StationQualityChecks() {
  const Actions = props => (
    <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
      <Button
        variant="light"
        size="sm"
        className="border-300 me-1 text-600"
        onClick={() => handleEditCheck(props.checkId)}
      >
        <FontAwesomeIcon icon="edit" />
      </Button>
      <Button
        variant="light"
        size="sm"
        className="border-300 text-600"
        onClick={() => handleDeleteCheck(props.checkId)}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </div>
  );

  const StepActions = props => (
    <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
      <Button
        variant="light"
        size="sm"
        className="border-300 me-1 text-600"
        onClick={() => handleEditStep(props.stepId)}
      >
        <FontAwesomeIcon icon="edit" />
      </Button>
      <Button
        variant="light"
        size="sm"
        className="border-300 text-600"
        onClick={() => handleDeleteStep(props.stepId)}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </div>
  );

  const imgApp = 'quality.check';
  const [loginData, setLoginData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState({});
  const [modalNewCheckOpen, setModalNewCheckOpen] = useState(false);
  const [modalEditCheckOpen, setModalEditCheckOpen] = useState(false);
  const [modalNewCheckStepOpen, setModalNewCheckStepOpen] = useState(false);
  const [modalEditCheckStepOpen, setModalEditCheckStepOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [qualityChecks, setQualityChecks] = useState([]);
  const [newCheck, setNewCheck] = useState({
    checkType: 0,
    measurable: 0,
    checkEvery: 1,
    checkUnit: '',
    stationId: 0,
    createdBy: 0
  });
  const [checkTypes, setCheckTypes] = useState([]);
  const [steps, setSteps] = useState([]);
  const [newStep, setNewStep] = useState({
    checkId: 0,
    stepNo: 0,
    instruction: '',
    createdBy: 0
  });
  const [selectedCheckId, setSelectedCheckId] = useState(0);
  const [selectedCheck, setSelectedCheck] = useState({});
  const [selectedStep, setSelectedStep] = useState({});
  const [editFileRows, setEditFileRows] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    let loggedData = JSON.parse(localStorage.getItem('login-data'));
    if (loggedData) setLoginData(loggedData);

    if (permissionId <= 0) {
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }

    getOptionsByKey('quality.check.type')
      .then(res => {
        if (res.success && res.data) {
          setCheckTypes(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Quality Options Check Failed!');
      });
    getStations()
      .then(res => {
        if (res?.success) {
          setStations(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Stations Failed!.');
      });
  }, []);

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const filteredStations = stations.filter(item =>
    item.stationName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectStation = station => {
    setSelectedStation(station);
    setSelectedCheckId(0);
    qualityChecks.map((c, i) => {
      const row = document.getElementById(`masterCheck${c.id}`);
      row.style.background = 'white';
      row.style.color = 'black';
    });
    getQualityChecksByStation(station.id)
      .then(res => {
        if (res.success) {
          setQualityChecks(res.data);
          setSteps([]);
        }
      })
      .catch(error => {
        swalToastError('Get Quality Check Failed!');
      });
  };

  const openModalNewCheck = () => {
    if (selectedStation && selectedStation.id > 0) {
      // if (qualityChecks.length > 0) {
      //   let m = measurables.find(
      //     x => x.id == Number(qualityChecks[0].measurable)
      //   );
      //   console.log(m);
      //   setNewCheck({
      //     ...newCheck,
      //     measurable: m.id,
      //     checkUnit: m.unit
      //   });
      //   console.log(newCheck);
      // }

      setModalNewCheckOpen(true);
      setNewCheck({
        ...newCheck,
        stationId: selectedStation.id,
        createdBy: loginData.userId
      });
    } else swalToastError('Please select a station');
  };

  const openModalNewCheckStep = () => {
    console.log(selectedCheckId);
    if (selectedCheckId < 1) {
      swalToastError('Please select a station');
    } else {
      let nextStep =
        steps.length > 0
          ? steps.reduce((max, obj) => (obj.stepNo > max.stepNo ? obj : max))
          : null;

      let nextStepNo = nextStep ? Number(nextStep.stepNo) + 1 : 1;
      setModalNewCheckStepOpen(true);
      setNewStep({
        ...newStep,
        checkId: selectedCheckId,
        stepNo: nextStepNo,
        createdBy: loginData.userId
      });
    }
  };

  const handleInputChange = e => {
    setNewCheck({ ...newCheck, [e.target.name]: e.target.value });
  };

  const handleEditInputChange = e => {
    setSelectedCheck({ ...selectedCheck, [e.target.name]: e.target.value });
  };

  const handleStepInputChange = e => {
    setNewStep({ ...newStep, [e.target.name]: e.target.value });
  };

  const handleEditStepInputChange = e => {
    setSelectedStep({ ...selectedStep, [e.target.name]: e.target.value });
  };

  const handleMeasureChange = e => {
    let m = measurables.find(x => x.id == Number(e.target.value));

    if (m) {
      console.log(m.unit);
      setNewCheck({
        ...newCheck,
        measurable: Number(e.target.value),
        checkUnit: m.unit
      });
    } else {
      setNewCheck({
        ...newCheck,
        measurable: 0,
        checkUnit: ''
      });
    }
  };

  const handleEditMeasureChange = e => {
    let m = measurables.find(x => x.id == Number(e.target.value));

    if (m) {
      console.log(m.unit);
      setSelectedCheck({
        ...selectedCheck,
        measurable: Number(e.target.value),
        unit: m.unit
      });
    } else {
      setSelectedCheck({
        ...selectedCheck,
        measurable: 0,
        unit: ''
      });
    }
  };

  const handleSaveNewCheck = () => {
    console.log(newCheck);
    if (newCheck.checkType < 1) {
      swalToastError('Please select a check type');
      return;
    }
    if (newCheck.measurable < 1) {
      swalToastError('Please select a measurable');
      return;
    }

    createStationQc(newCheck)
      .then(res => {
        if (res?.success && res.data && res.data.newQc) {
          getQualityChecksByStation(selectedStation.id)
            .then(res => {
              if (res.success) {
                setQualityChecks(res?.data);
              }
            })
            .catch(error => {
              swalToastError('Get Quality Check Failed!');
            });
          clearNewCheckForm();
          swalToastSuccess('Successfully');
        } else {
          swalToastError('Failed to create quality check');
        }
      })
      .catch(err => {
        console.log(err);
        swalToastError('Error. Something went wrong');
      });
  };

  const handleEditCheck = checkId => {
    setModalEditCheckOpen(true);
  };

  const handleSaveEditedCheck = () => {
    const payload = {
      id: selectedCheckId,
      checkType: selectedCheck.checkType,
      measurable: selectedCheck.measurable,
      checkEvery: selectedCheck.checkEvery,
      updatedBy: loginData.userId
    };

    updateStationQc(payload)
      .then(res => {
        if (res?.success && res.data && res.data.success) {
          swalToastSuccess('Quality Check has been updated');
          setModalEditCheckOpen(false);
          getQualityChecksByStation(selectedStation.id)
            .then(res => {
              if (res.success) {
                setQualityChecks(res?.data);
              }
            })
            .catch(error => {
              swalToastError('Get Quality Check Failed!');
            });
        } else {
          swalToastError('Failed to update check');
        }
      })
      .catch(err => {
        console.log(err);
        swalToastError('Error. Something went wrong');
      });
  };

  const handleSaveNewStep = () => {
    console.log(newStep);
    console.log(files);

    if (!newStep.instruction) {
      swalToastError('Please provide step instruction');
      return;
    }

    createStationQcStep(newStep)
      .then(res => {
        if (res?.success && res.data && res.data.success) {
          uploadImagesToS3AndSaveRecords(
            files,
            selectedCheckId,
            res.newStep.id
          );
          getQualityCheckStepsByCheck(selectedCheckId, imgApp)
            .then(res => {
              if (res.success) {
                setSteps(res?.data);
              }
            })
            .catch(error => {
              swalToastError('Get Quality Check Steps Failed!');
            });
          clearNewStepForm();
          swalToastSuccess('Successfully');
        } else {
          swalToastError('Failed to create quality check');
        }
      })
      .catch(err => {
        console.log(err);
        swalToastError('Error. Something went wrong');
      });
  };

  const handleDeleteCheck = checkId => {
    console.log(checkId);

    Swal.fire({
      title: 'Delete this check?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteStationQc(checkId)
          .then(res => {
            if (res?.success && res.data) {
              let updatedChecks = qualityChecks.filter(m => m.id !== checkId);
              setQualityChecks(updatedChecks);
              swalToastSuccess('Deleted');

              getQualityCheckStepsByCheck(checkId, imgApp)
                .then(res => {
                  if (res.success && res.data && res.data.length > 0) {
                    let _steps = res.data;
                    var allImgs = [];
                    res.data.map(s => {
                      allImgs.push(...s.images);
                    });

                    if (allImgs.length > 0) {
                      let keys = allImgs.map(x =>
                        x.path.replace('s3/eva-files/', '')
                      );
                      deleteMultipleS3Files(keys).then(res => {
                        if (res.success) {
                          _steps.map(s => {
                            console.log(s);
                            deleteAttachmentsByRecordId(s.id, imgApp)
                              .then(res => {
                                if (!res.success || !res.data) {
                                  swalToastError('Failed to delete images');
                                }
                              })
                              .catch(error => {
                                swalToastError('Failed to delete images');
                              });
                          });
                        }
                      });
                    }
                    deleteStationQcStepsByCheck(checkId)
                      .then(res => {
                        if (res?.success && res.data) {
                          setSteps([]);
                        }
                      })
                      .catch(error => {
                        swalToastError('Delete Station QC Check Step Faield!');
                      });
                  }
                })
                .catch(error => {
                  swalToastError('Get Quality Check Steps Failed!');
                });
            } else {
              swalToastError('Failed to delete check');
            }
          })
          .catch(err => {
            console.log(err);
            swalToastError('Error. Something went wrong');
          });
      }
    });
  };

  const uploadImagesToS3AndSaveRecords = (uploadedImages, checkId, stepId) => {
    const formData = new FormData();
    if (uploadedImages.length > 0) {
      uploadedImages.map(item => {
        formData.append('files', item);
      });
    }
    const prefix = `eva-scan-station/quality-checks/${checkId}/${stepId}/`;

    uploadMultiFilesToS3(formData, prefix)
      .then(res => {
        if (res?.success && res.mappings.length > 0) {
          const attachments = [];
          let attachmentItem = {};
          res.mappings.map(item => {
            attachmentItem = {
              name: item.originalName,
              hashedName: item.hashedName,
              type: item.extension,
              size: item.size,
              path: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}${item.hashedName}`,
              recordId: parseInt(stepId),
              recordValue: '',
              data: '',
              imgWidth: 0,
              imgHeight: 0,
              app: imgApp
            };
            attachments.push(attachmentItem);
          });

          if (attachments.length > 0) {
            insertMultiAttachmentRecords(attachments)
              .then(res => {
                if (res?.success && res.data) {
                  swalToastSuccess('Attachments have been stored');
                } else {
                  swalToastError('Failed to upload images');
                }
              })
              .catch(err => {
                console.log(err);
                swalToastError('Error. Something went wrong');
              });
          }
        }
      })
      .catch(error => {
        swalToastError('Upload Files Failed!');
      });
  };

  const clearNewCheckForm = () => {
    setModalNewCheckOpen(false);
    setNewCheck({
      checkType: 0,
      measurable: 0,
      checkEvery: 1,
      checkUnit: '',
      stationId: 0,
      createdBy: 0
    });
  };

  const clearNewStepForm = () => {
    setModalNewCheckStepOpen(false);
    setFiles([]);
    setNewStep({
      checkId: 0,
      stepNo: 0,
      instruction: '',
      createdBy: 0
    });
  };

  const handleMasterQcClicked = (e, checkId) => {
    console.log(checkId);
    setSelectedCheckId(checkId);
    getQualityCheckById(checkId)
      .then(res => {
        if (res.success && res.data) {
          let measure = measurables.find(x => x.id === res.data.measurable);
          setSelectedCheck({ ...res.data, unit: measure.unit });
        }
      })
      .catch(error => {
        swalToastError('Get Quality Check Failed!');
      });

    const row = document.getElementById(`masterCheck${checkId}`);
    row.style.background = '#2c7be5';
    row.style.color = 'white';

    getQualityCheckStepsByCheck(checkId, imgApp)
      .then(res => {
        if (res.success) {
          setSteps(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Quality Check Steps Failed!');
      });

    qualityChecks.map((c, i) => {
      if (c.id != checkId && c.id != 0) {
        const row = document.getElementById(`masterCheck${c.id}`);
        row.style.background = 'white';
        row.style.color = 'black';
      }
    });
  };

  const handleDeleteStep = stepId => {
    console.log(stepId);
    Swal.fire({
      title: 'Delete this step?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteStationQcStep(stepId)
          .then(res => {
            if (res?.success && res.data) {
              swalToastSuccess('Deleted');
              const step = steps.find(x => x.id == stepId);
              if (step.images.length > 0) {
                let keys = step.images.map(x =>
                  x.path.replace('s3/eva-files/', '')
                );
                deleteMultipleS3Files(keys).then(res => {
                  if (res.success) {
                    deleteAttachmentsByRecordId(stepId, imgApp)
                      .then(res => {
                        if (!res.success || !res.data) {
                          swalToastError('Failed to delete images');
                        }
                      })
                      .catch(error => {
                        swalToastError('Failed to delete images');
                      });
                  }
                });
              }
              let updatedSteps = steps.filter(s => s.id !== stepId);
              setSteps(updatedSteps);
            } else {
              swalToastError('Failed to delete check');
            }
          })
          .catch(error => {
            swalToastError('Delete Station QC Step Faield!');
          });
      }
    });
  };

  const handleEditStep = stepId => {
    console.log(stepId);
    const _step = steps.find(x => x.id == stepId);
    setSelectedStep(_step);

    let rows = [];

    _step.images.map((f, i) => {
      rows.push(
        <tr className="hover-actions-trigger" key={`wfr${i}`}>
          <td>{f.name}</td>
          <td>{dayjs(f.created).format('DD/MM/YYYY HH:mm')}</td>
          <td className="w-auto">
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
              <Button
                variant="light"
                size="sm"
                className="border-300 text-600"
                onClick={() => handleDeleteImage(_step.images, f.id, f.path)}
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </div>
          </td>
        </tr>
      );
    });

    setEditFileRows(rows);
    setModalEditCheckStepOpen(true);
  };

  const handleDeleteImage = (images, attachmentId, s3Path) => {
    console.log(attachmentId, s3Path);
    Swal.fire({
      title: 'Delete this image?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteS3File(s3Path.replace('s3/eva-files/', ''))
          .then(res => {
            if (res?.success) {
              deleteAttachmentRecord(attachmentId)
                .then(res1 => {
                  if (res1?.success && res1.data) {
                    let rows = [];
                    let updatedImages = images.filter(
                      x => x.id != attachmentId
                    );
                    if (updatedImages.length > 0) {
                      updatedImages.map((f, i) => {
                        rows.push(
                          <tr className="hover-actions-trigger" key={`wfr${i}`}>
                            <td>{f.name}</td>
                            <td>
                              {dayjs(f.created).format('DD/MM/YYYY HH:mm')}
                            </td>
                            <td className="w-auto">
                              <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
                                <Button
                                  variant="light"
                                  size="sm"
                                  className="border-300 text-600"
                                  onClick={() =>
                                    handleDeleteImage(
                                      updatedImages,
                                      f.id,
                                      f.path
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon="trash-alt" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      });
                    } else {
                      rows.push(
                        <tr className="hover-actions-trigger" key={`wfr0`}>
                          <td colSpan={3}>No images</td>
                        </tr>
                      );
                    }

                    setEditFileRows(rows);
                    swalToastSuccess('Deleted');
                  }
                })
                .catch(err => {
                  console.log(err);
                  swalToastError('Failed to delete');
                });
            }
          })
          .catch(err => {
            console.log(err);
            swalToastError('Failed to delete');
          });
      }
    });
  };

  const handleSaveEditStep = () => {
    console.log(selectedStep);
    var payload = {
      id: selectedStep.id,
      instruction: selectedStep.instruction,
      updatedBy: loginData.userId
    };
    updateStationQcStep(payload)
      .then(res => {
        if (res?.success && res.data && res.data.success) {
          if (files.length > 0) {
            uploadImagesToS3AndSaveRecords(
              files,
              selectedCheckId,
              res.data?.updatedStep.id
            );
          }
          getQualityCheckStepsByCheck(selectedCheckId, imgApp)
            .then(res => {
              if (res.success) {
                setSteps(res?.data);
              }
            })
            .catch(error => {
              swalToastError('Get Quality Check Steps Failed!');
            });
          setSelectedStep({});
          setModalEditCheckStepOpen(false);
          swalToastSuccess('Step has been updated');
        } else {
          swalToastError('Failed to update step');
        }
      })
      .catch(err => {
        console.log(err);
        swalToastError('Error. Something went wrong');
      });
  };

  let checkTypeOptions = [];
  checkTypeOptions.push(
    <option key={'type0'} value={0}>
      Select a type
    </option>
  );
  checkTypes.map((type, i) => {
    checkTypeOptions.push(
      <option key={'type' + i + 1} value={type.optionId}>
        {type.optionName}
      </option>
    );
  });

  let measurableOptions = [];
  measurableOptions.push(
    <option key={'measure0'} value={0}>
      Select a measurable
    </option>
  );
  measurables.map((m, i) => {
    measurableOptions.push(
      <option key={'measure' + i + 1} value={m.id}>
        {m.name}
      </option>
    );
  });

  let dataRows = [];
  if (qualityChecks.length > 0) {
    qualityChecks.map((check, i) => {
      var measure = measurables.find(x => x.id === check.measurable);
      var measureDesc = `Every ${check.checkEvery} ${measure.unit}`;

      dataRows.push(
        <tr
          className="hover-actions-trigger"
          key={i}
          id={`masterCheck${check.id}`}
          style={{ cursor: 'pointer' }}
          onClick={e => handleMasterQcClicked(e, check.id)}
        >
          <td>{check.checkTypeName}</td>
          <td>{measureDesc}</td>

          <td className="w-auto">
            <Actions checkId={check.id} />
          </td>
        </tr>
      );
    });
  } else {
    dataRows.push(
      <tr className="hover-actions-trigger" key={0}>
        <td colSpan={4} className="text-center">
          No quality checks found
        </td>
      </tr>
    );
  }

  let stepDataRows = [];
  console.log(steps);
  if (steps.length > 0) {
    steps.map((s, i) => {
      stepDataRows.push(
        <tr className="hover-actions-trigger" key={i + 1}>
          <td>{s.stepNo}</td>
          <td>{s.instruction}</td>
          <td>
            <PhotoProvider maskOpacity={0.5}>
              <div className="foo">
                {s.images.map((img, index) => (
                  <PhotoView
                    key={index}
                    src={`${
                      process.env.REACT_APP_FILE_API_HOST
                    }/files/download/${
                      process.env.REACT_APP_BUCKET_NAME
                    }?key=${img.path.replace('s3/eva-files/', '')}`}
                  >
                    <Image
                      key={`img${img.id}`}
                      src={`${
                        process.env.REACT_APP_FILE_API_HOST
                      }/files/download/${
                        process.env.REACT_APP_BUCKET_NAME
                      }?key=${img.path.replace('s3/eva-files/', '')}`}
                      alt=""
                      fluid
                      width={150}
                      height={'auto'}
                      style={{ padding: '5px', cursor: 'pointer' }}
                    ></Image>
                  </PhotoView>
                ))}
              </div>
            </PhotoProvider>
          </td>
          <td className="w-auto">
            <StepActions stepId={s.id} />
          </td>
        </tr>
      );
    });
  } else {
    stepDataRows.push(
      <tr className="hover-actions-trigger" key={0}>
        <td colSpan={4} className="text-center">
          No steps found
        </td>
      </tr>
    );
  }

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <>
      <div className="g-3 mt-2 row">
        <div className="col-lg-3 col-sm-12">
          <Card>
            <Card.Header>
              <h5 className="mb-0">Station List</h5>
            </Card.Header>
            <Card.Body style={{ fontSize: '13px' }}>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <br></br>
              <ListGroup style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                {filteredStations.map(item => (
                  <ListGroup.Item
                    key={item.id}
                    action
                    href={`#${item.id}`}
                    onClick={() => handleSelectStation(item)}
                  >
                    {item.stationName}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-9 col-sm-12">
          <Card>
            <Card.Header>
              <h5 className="mb-1">Quality Checks</h5>
              <h6>Station: {selectedStation.stationName}</h6>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-sm-8"></div>
                <div className="col-sm-4 text-end">
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className="me-2"
                    onClick={openModalNewCheck}
                  >
                    <span className="d-none d-sm-inline-block ms-1">New</span>
                  </IconButton>
                </div>
              </div>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th scope="col" className="col-sm-2">
                      Check Type
                    </th>
                    <th scope="col" className="col-sm-2">
                      Measurable
                    </th>
                    <th scope="col" className="col-sm-1"></th>
                  </tr>
                </thead>
                <tbody>{dataRows}</tbody>
              </Table>
            </Card.Body>
          </Card>
          <br></br>
          <Card>
            <Card.Header>
              <h5 className="mb-1">Check Steps</h5>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="text-end">
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className="me-2"
                    onClick={openModalNewCheckStep}
                  >
                    <span className="d-none d-sm-inline-block ms-1">
                      New Step
                    </span>
                  </IconButton>
                </div>
              </div>

              <Table hover responsive>
                <thead>
                  <tr>
                    <th scope="col" className="col-lg-2 col-sm-2">
                      Step no.
                    </th>
                    <th scope="col" className="col-lg-5 col-sm-5">
                      Instruction
                    </th>
                    <th scope="col" className="col-lg-4 col-sm-4">
                      Images
                    </th>
                    <th scope="col" className="col-lg-1 col-sm-1"></th>
                  </tr>
                </thead>
                <tbody>{stepDataRows}</tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Modal
        show={modalNewCheckOpen}
        onHide={() => setModalNewCheckOpen(false)}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h4">New Quality Check</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-6">
                <Form.Label>Check type:</Form.Label>
                <Form.Control
                  as="select"
                  name="checkType"
                  value={newCheck.checkType}
                  onChange={handleInputChange}
                  required
                >
                  {checkTypeOptions}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-4">
                <Form.Label>Measurable:</Form.Label>
                <Form.Control
                  as="select"
                  name="measurable"
                  value={newCheck.measurable}
                  onChange={handleMeasureChange}
                  required
                >
                  {measurableOptions}
                </Form.Control>
              </Col>
              <Col className="col-lg-4">
                <Form.Label>Every:</Form.Label>
                <Form.Control
                  name="checkEvery"
                  type="number"
                  min={1}
                  value={newCheck.checkEvery}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col className="col-lg-4">
                <Form.Label>Unit:</Form.Label>
                <Form.Control
                  type="text"
                  name="checkUnit"
                  value={newCheck.checkUnit}
                  readOnly={true}
                />
              </Col>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleSaveNewCheck}>
            Save
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => setModalNewCheckOpen(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalEditCheckOpen}
        onHide={() => setModalEditCheckOpen(false)}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h4">Edit Quality Check</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-6">
                <Form.Label>Check type:</Form.Label>
                <Form.Control
                  as="select"
                  name="checkType"
                  value={selectedCheck.checkType}
                  onChange={handleEditInputChange}
                  required
                >
                  {checkTypeOptions}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-4">
                <Form.Label>Measurable:</Form.Label>
                <Form.Control
                  as="select"
                  name="measurable"
                  value={selectedCheck.measurable}
                  onChange={handleEditMeasureChange}
                  required
                >
                  {measurableOptions}
                </Form.Control>
              </Col>
              <Col className="col-lg-4">
                <Form.Label>Every:</Form.Label>
                <Form.Control
                  name="checkEvery"
                  type="number"
                  min={1}
                  value={selectedCheck.checkEvery}
                  onChange={handleEditInputChange}
                  required
                />
              </Col>
              <Col className="col-lg-4">
                <Form.Label>Unit:</Form.Label>
                <Form.Control
                  type="text"
                  name="checkUnit"
                  readOnly={true}
                  value={selectedCheck.unit}
                />
              </Col>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleSaveEditedCheck}>
            Save
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => setModalEditCheckOpen(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Step modals */}
      <Modal
        show={modalNewCheckStepOpen}
        onHide={() => setModalNewCheckStepOpen(false)}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h4">New Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-4">
                <Form.Label>Step:</Form.Label>
                <Form.Control
                  name="stepNo"
                  type="text"
                  value={newStep.stepNo}
                  readOnly={true}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-12">
                <Form.Label>Instruction:</Form.Label>
                <Form.Control
                  name="instruction"
                  as="textarea"
                  rows={3}
                  value={newCheck.instruction}
                  onChange={handleStepInputChange}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Label>Upload Images (optinal):</Form.Label>
            <div {...getRootProps({ className: 'dropzone-area py-4' })}>
              <input {...getInputProps()} />
              <Flex justifyContent="center">
                <MdOutlineCloudUpload width={20} className="me-2 mt-1" />
                <p className="fs-0 mb-0 text-400">
                  Drop your images here or <u>browse</u>
                </p>
              </Flex>
            </div>
            <div
              className="mt-1"
              style={{ maxHeight: '200px', overflowY: 'auto' }}
            >
              {files.map(file => (
                <Flex
                  alignItems="center"
                  className="py-1 border-bottom btn-reveal-trigger"
                  key={file.path}
                >
                  <Image
                    rounded
                    width={40}
                    height={40}
                    src={file.preview}
                    alt={file.path}
                  />
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="ms-3 flex-1"
                  >
                    <div>
                      <h6>{file.path}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs--1 text-400 line-height-1">
                          <strong>{getSize(file.size)}</strong>
                        </p>
                      </Flex>
                    </div>
                  </Flex>
                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleRemove(file.path)}
                      >
                        Remove
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleSaveNewStep}>
            Save
          </Button>
          <Button className="btn btn-secondary">Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalEditCheckStepOpen}
        onHide={() => setModalEditCheckStepOpen(false)}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h4">Edit Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-4">
                <Form.Label>Step:</Form.Label>
                <Form.Control
                  name="stepNo"
                  type="text"
                  value={selectedStep.stepNo}
                  readOnly={true}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-12">
                <Form.Label>Instruction:</Form.Label>
                <Form.Control
                  name="instruction"
                  as="textarea"
                  rows={3}
                  value={selectedStep.instruction}
                  onChange={handleEditStepInputChange}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-8">
                <table
                  className="table table-hover"
                  style={{ fontSize: '13px' }}
                >
                  <thead>
                    <tr>
                      <th scope="col" width="60%">
                        Filename
                      </th>
                      <th scope="col" width="30%">
                        Uploaded
                      </th>
                      <th scope="col" width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>{editFileRows}</tbody>
                </table>
              </Col>
            </Form.Group>
            <Form.Label>Upload Images (optinal):</Form.Label>
            <div {...getRootProps({ className: 'dropzone-area py-4' })}>
              <input {...getInputProps()} />
              <Flex justifyContent="center">
                <MdOutlineCloudUpload width={20} className="me-2 mt-1" />
                <p className="fs-0 mb-0 text-400">
                  Drop your images here or <u>browse</u>
                </p>
              </Flex>
            </div>
            <div
              className="mt-1"
              style={{ maxHeight: '200px', overflowY: 'auto' }}
            >
              {files.map(file => (
                <Flex
                  alignItems="center"
                  className="py-1 border-bottom btn-reveal-trigger"
                  key={file.path}
                >
                  <Image
                    rounded
                    width={40}
                    height={40}
                    src={file.preview}
                    alt={file.path}
                  />
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="ms-3 flex-1"
                  >
                    <div>
                      <h6>{file.path}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs--1 text-400 line-height-1">
                          <strong>{getSize(file.size)}</strong>
                        </p>
                      </Flex>
                    </div>
                  </Flex>
                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleRemove(file.path)}
                      >
                        Remove
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleSaveEditStep}>
            Save
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setModalEditCheckStepOpen(false);
              getQualityCheckStepsByCheck(selectedCheck.id, imgApp)
                .then(res => {
                  if (res.success) {
                    setSteps(res?.data);
                  }
                })
                .catch(error => {
                  swalToastError('Get Quality Check Steps Failed!');
                });
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <AccessDenied></AccessDenied>
  );
}
