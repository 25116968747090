import axiosHelper from 'helpers/axiosHepler';

export const getFramesStillage = date => {
  const url = `${process.env.REACT_APP_API_2_HOST}/plotting-stillage/${date}`;
  return axiosHelper.get(url);
};

export const getStillgePhotos = (stillageId, app) => {
  const url = `/attachment-management/eva-attachments/multiple/${stillageId}?app=${app}`;
  return axiosHelper.get(url);
};
