import axiosHelper from 'helpers/axiosHepler';

const getTrackingGlassItems = barcode => {
  const url = `/Panel/tracking-glass?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const checkGlassGeorgianImgPath = barcode => {
  const url = `/Panel/check-georgian-glass-image?barcode=${barcode}`;
  return axiosHelper.get(url);
};

export { checkGlassGeorgianImgPath, getTrackingGlassItems };
