import axiosHelper from 'helpers/axiosHepler';

const getLastestCheckByMachine = machineId => {
  const url = `/machine-check-logs/lastest/${machineId}`;
  return axiosHelper.get(url);
};

const logMachineCheck = payload => {
  const url = `/machine-check-logs`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const resolveIssueCheck = (payload, app) => {
  const url = `/machine-check-logs?app=${app}`;
  const data = payload;
  return axiosHelper.patch(url, data);
};

export { getLastestCheckByMachine, logMachineCheck, resolveIssueCheck };
