import axiosHelper from 'helpers/axiosHepler';

const startBatch = (
  barcode,
  startStage,
  userId,
  stationId,
  sawCuttingProduct
) => {
  const url = `/EvaScanStation/batch/start?barcode=${barcode}&startStage=${startStage}&userId=${userId}&stationId=${stationId}&product=${sawCuttingProduct}`;
  return axiosHelper.post(url);
};

const completeBatch = payload => {
  const url = `/EvaScanStation/batch/complete`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const getBatchInfo = barcode => {
  const url = `/EvaScanStation/batch-info?barcode=${barcode}`;
  return axiosHelper.get(url);
};

const getCuttingItems = (currentDate, payloadColours, isOrderView, search) => {
  const url = `/Panel/cuttings?date=${currentDate}&colours=${payloadColours}&isOrderView=${isOrderView}&search=${search}`;
  return axiosHelper.get(url);
};

const getCompositeDoorJobs = (currentDate, payloadColours, search) => {
  const url = `/Panel/composite-door-jobs?date=${currentDate}&colours=${payloadColours}&search=${search}`;
  return axiosHelper.get(url);
};

const getRemakeJobs = (currentDate, payloadColours, search) => {
  const url = `/Panel/remake-jobs?date=${currentDate}&colours=${payloadColours}&search=${search}`;
  return axiosHelper.get(url);
};

const getCuttingitemDetails = headerId => {
  const url = `/Panel/cutting-details?headerId=${headerId}`;
  return axiosHelper.get(url);
};

const getCompositeDoorJobDetails = headerId => {
  const url = `/Panel/composite-door-job-details?headerId=${headerId}`;
  return axiosHelper.get(url);
};

const getPacksByHeaderId = headerId => {
  const url = `/Panel/ancil-packs?headerId=${headerId}`;
  return axiosHelper.get(url);
};

const deletePack = (boxId, userId, stationId, requiredDate) => {
  const url = `/Panel/ancil-pack/${boxId}?userId=${userId}&stationId=${stationId}&requiredDate=${requiredDate}`;
  return axiosHelper.delete(url);
};

const getItemsInPack = boxId => {
  const url = `/Panel/ancil-pack?boxId=${boxId}`;
  return axiosHelper.get(url);
};

const deleteItemInPack = (item, userId, boxId, stationId, requiredDate) => {
  const url = `/Panel/ancil-pack-item/${item.itemNo}/${item.partsItemId}?boxId=${boxId}&userId=${userId}&stationId=${stationId}&requiredDate=${requiredDate}`;
  return axiosHelper.delete(url);
};

const getItemLinkPack = headerId => {
  const url = `/Panel/item-link-to-cut?headerId=${headerId}`;
  return axiosHelper.get(url);
};

const moveToProductionStage = barcode => {
  const url = `/QC/move-to-production-stage`;
  const data = { barcode };
  return axiosHelper.put(url, data);
};

export {
  completeBatch,
  deleteItemInPack,
  deletePack,
  getBatchInfo,
  getCompositeDoorJobDetails,
  getCompositeDoorJobs,
  getCuttingItems,
  getCuttingitemDetails,
  getItemLinkPack,
  getItemsInPack,
  getPacksByHeaderId,
  getRemakeJobs,
  moveToProductionStage,
  startBatch
};
