import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import AccessDenied from 'components/errors/AccessDenied';
import dayjs from 'dayjs';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { getSize } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import {
  BsFillFileEarmarkExcelFill,
  BsFillFileEarmarkPdfFill,
  BsFillFileEarmarkWordFill
} from 'react-icons/bs';
import { MdOutlineVideoLibrary, MdVideoLibrary } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import {
  deleteAttachmentRecord,
  deleteS3File,
  getAttachmentsByRecordId,
  insertMultiAttachmentRecords,
  uploadMultiFilesToS3
} from 'services/attachmentService';
import { getStations } from 'services/stationService';
import Swal from 'sweetalert2';
import * as yup from 'yup';

const fileTypes = {
  image: ['jpeg', 'png', 'jpg'],
  pdf: ['pdf'],
  msWord: ['doc', 'docx'],
  msExcel: ['xlsx', 'slx', 'csv'],
  video: ['mp4']
};

const DOCS_TRAINING_APP = 'training.docs';

const TrainingDocuments = () => {
  const [stations, setStations] = useState([]);
  const [files, setFiles] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [currentStation, setCurrentStation] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [uploaded, setUploaded] = useState([]);
  const [options, setOptions] = useState([]);
  const [language, setLanguage] = useState('en');
  const [languageListing, setLanguageListing] = useState('en');
  const [documentType, setDocumentType] = useState('sops');

  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    if (permissionId <= 0) {
      let loggedData = JSON.parse(localStorage.getItem('login-data'));
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }
    getStations()
      .then(res => {
        setStations(res.data);
        setIsloading(false);
        if (res.data.length > 0) {
          const opts = res.data.map((item, ind) => {
            return {
              value: item.id,
              label: item.stationName,
              path: item.stationPath
            };
          });
          setOptions(opts);
        }
      })
      .catch(error => {
        swalToastError('Get Stations Failed!.');
      });
  }, []);

  useEffect(() => {
    if (currentStation && languageListing && !isLoading) {
      getAttachmentsByRecordId(currentStation, DOCS_TRAINING_APP)
        .then(res => {
          if (res.success && res.data && res.data.length > 0) {
            const data = sortData(res.data);
            const dataFilter = data.filter(
              item => item.language === languageListing
            );
            setUploaded(dataFilter);
          } else {
            setUploaded([]);
          }
        })
        .catch(error => {
          swalToastError('Get Attachments Failed!.');
        });
    }
  }, [currentStation, languageListing, isLoading]);

  const sortData = data => {
    return data.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
  };

  const schema = yup.object().shape({
    uploadedFiles: yup.array().min(1, 'This field is required.'),
    stationList: yup.array().min(1, 'This field is required.'),
    language: yup.string(),
    documentType: yup.string()
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({
    defaultValues: {
      uploadedFiles: [],
      stationList: [],
      language: language,
      documentType: documentType
    },
    resolver: yupResolver(schema)
  });

  const { getRootProps, getInputProps } = useDropzone({
    noClick: stationList.length > 0 ? false : true,
    noDrag: stationList.length > 0 ? false : true,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.ms-excel': ['.xlsx', '.slx', '.csv'],
      'video/*': ['.mp4']
    },
    onDrop: acceptedFiles => {
      if (errors.uploadedFiles) {
        clearErrors('uploadedFiles');
      }
      setValue('uploadedFiles', acceptedFiles);
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const handleRemove = path => {
    const filteredFiles = files.filter(file => file.path !== path);
    setFiles(filteredFiles);
    setValue('uploadedFiles', filteredFiles.length ? filteredFiles : []);
  };

  const onSubmit = data => {
    setIsloading(true);
    const formData = new FormData();
    if (data.uploadedFiles.length > 0) {
      data.uploadedFiles.map((item, index) => {
        formData.append('files', item);
      });
    }

    data.stationList.forEach(station => {
      const prefix = `eva-scan-station/training-documents/${station.path}`;
      uploadMultiFilesToS3(formData, prefix)
        .then(res => {
          if (res?.success && res.mappings?.length > 0) {
            const attachments = [];
            let attachmentItem = {};
            res.mappings.map(item => {
              attachmentItem = {
                name: item.originalName,
                hashedName: item.hashedName,
                type: item.extension,
                size: item.size,
                path: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}/${item.hashedName}`,
                recordId: station.value,
                recordValue: '',
                data: '',
                imgWidth: 0,
                imgHeight: 0,
                language: language,
                documentType: documentType,
                app: 'training.docs'
              };
              attachments.push(attachmentItem);
            });

            insertMultiAttachmentRecords(attachments)
              .then(res => {
                if (res.success && res.data) {
                  swalToastSuccess(
                    `Attachments Uploaded To ${station.label} Successfully`
                  );

                  setFiles([]);
                  setValue('uploadedFiles', null);
                  setIsloading(false);
                }
              })
              .catch(error => {
                console.log(error);
                swalToastError('Something Went Wrong!');
                setIsloading(false);
              });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.code === 'ERR_NETWORK') {
            swalToastError('Files Size Too Large!');
          } else {
            swalToastError('Something Went Wrong!');
          }
          setIsloading(false);
        });
    });
  };

  const getFileExtention = fileName => {
    return fileName.split('.').pop();
  };

  const generateAttachment = file => {
    if (fileTypes.image.includes(getFileExtention(file.name))) {
      return (
        <img
          title={file.name}
          width={30}
          height={30}
          src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${
            process.env.REACT_APP_BUCKET_NAME
          }?key=${file.path.replace('s3/eva-files/', '')}`}
          alt={'document'}
        />
      );
    } else if (fileTypes.msWord.includes(getFileExtention(file.name))) {
      return (
        <div>
          <BsFillFileEarmarkWordFill size={30} />
        </div>
      );
    } else if (fileTypes.msExcel.includes(getFileExtention(file.name))) {
      return (
        <div>
          <BsFillFileEarmarkExcelFill size={30} />
        </div>
      );
    } else if (fileTypes.pdf.includes(getFileExtention(file.name))) {
      return (
        <div>
          <BsFillFileEarmarkPdfFill size={30} />
        </div>
      );
    } else if (fileTypes.video.includes(getFileExtention(file.name))) {
      return <MdOutlineVideoLibrary size={30} />;
    }
  };

  const handleDeleteUploaded = file => {
    let item = file.file;
    const key = item.path.replace('s3/eva-files/', '');
    Swal.fire({
      title: 'Delete this document?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteS3File(key)
          .then(res => {
            deleteAttachmentRecord(item.id)
              .then(response => {
                if (response.success && response.data) {
                  setUploaded(uploaded.filter(file => file.id !== item.id));
                  swalToastSuccess('Delete Attachment Successfully');
                } else {
                  swalToastError('Something Went Wrong!');
                }
              })
              .catch(error => {
                console.log(error);
                swalToastError('Something Went Wrong!');
              });
          })
          .catch(error => {
            console.log(error);
            swalToastError('Something Went Wrong!');
          });
      }
    });
  };

  const handleChangeStation = value => {
    setValue('stationList', value);
    setStationList(value);
  };

  const handleChangeLanguage = event => {
    const value = event.target.value;
    setValue('language', value);
    setLanguage(value);
  };

  const handleChanegDocsType = event => {
    const value = event.target.value;
    setValue('documentType', value);
    setDocumentType(value);
  };

  const genrateOption = stations => {
    return stations.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.stationName}
        </option>
      );
    });
  };

  const handleChangeCurrentStation = event => {
    setCurrentStation(event.target.value);
  };

  const handleChangeLanguageListing = event => {
    setLanguageListing(event.target.value);
  };

  const Actions = file => (
    <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
      <Button
        variant="light"
        size="sm"
        className="border-300 text-500"
        onClick={() => handleDeleteUploaded(file)}
      >
        <FontAwesomeIcon color="#d9534f" icon="trash-alt" />
      </Button>
    </div>
  );

  const generateTbodyByDocType = (files, type) => {
    return files.map((file, ind) => (
      <tr key={ind} className="hover-actions-trigger">
        <td style={{ paddingLeft: '3rem' }}>{generateAttachment(file)}</td>
        <td>{file.name}</td>
        <td>
          <Actions file={file} />
        </td>
      </tr>
    ));
  };

  const generateTbody = attachments => {
    const sops = attachments.filter(file => file.documentType === 'sops');
    const sips = attachments.filter(file => file.documentType === 'sips');
    const evt = attachments.filter(file => file.documentType === 'evt');

    return (
      <>
        <>
          <tr>
            <td colSpan={2} className="fst-italic fw-bold">
              SOPS
            </td>
          </tr>
          {sops.length > 0 && generateTbodyByDocType(sops, 'sops')}
        </>
        <>
          <tr>
            <td colSpan={2} className="fst-italic fw-bold">
              SIPS
            </td>
          </tr>
          {sips.length > 0 && generateTbodyByDocType(sips, 'sips')}
        </>
        <>
          <tr>
            <td colSpan={2} className="fst-italic fw-bold">
              EVT
            </td>
          </tr>
          {evt.length > 0 && generateTbodyByDocType(evt, 'evt')}
        </>
      </>
    );
  };

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <div className="row gap-2">
      {/* document uploader */}
      <Card
        bg={'light'}
        className="col-lg-6 col-md-12 p-0"
        style={{ height: 'fit-content' }}
      >
        <Card.Header>
          <h5>Documents Uploader</h5>
        </Card.Header>
        <Card.Body>
          {stations && stations.length > 0 ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4 d-flex justify-content-between flex-wrap">
                <div style={{ width: '230px' }}>
                  <div> Stations </div>
                  <MultiSelect
                    options={options}
                    isMulti={true}
                    onChange={handleChangeStation}
                  ></MultiSelect>
                  <small className="text-danger">
                    {errors.stationList?.message}
                  </small>
                </div>
                <div>
                  <div> Language </div>
                  <Form.Select
                    className="cursor-pointer"
                    onChange={handleChangeLanguage}
                    aria-label="Default select example"
                    value={language}
                  >
                    <option value="en">English</option>
                    <option value="ro">Romania</option>
                    <option value="po">Polish</option>
                  </Form.Select>
                  <small className="text-danger">
                    {errors.language?.message}
                  </small>
                </div>

                <div>
                  <div> Document Type </div>
                  <Form.Select
                    className="cursor-pointer"
                    onChange={handleChanegDocsType}
                    aria-label="Default select example"
                    value={documentType}
                  >
                    <option value="sops">SOPS</option>
                    <option value="sips">SIPS</option>
                    <option value="evt">EVT</option>
                  </Form.Select>
                  <small className="text-danger">
                    {errors.documentType?.message}
                  </small>
                </div>
              </div>

              <div
                style={{
                  background: stationList.length > 0 ? '' : '#cccccc',
                  cursor: stationList.length > 0 ? 'pointer' : 'default'
                }}
                {...getRootProps({
                  className: `${classNames(`dropzone-area py-lg-4 py-md-3`, {
                    'is-invalid': errors.uploadedFiles
                  })}`
                })}
              >
                <input name="uploadedFiles" {...getInputProps()} />
                <Flex justifyContent="center" alignItems="center">
                  <p className="fs-0 mb-0 text-700">
                    Drop your documents here or Browse
                  </p>
                </Flex>
              </div>
              <div className="invalid-feedback">
                {errors.uploadedFiles?.message}
              </div>
              <div
                className="mt-1 max-vh-lg-25"
                style={{ maxHeight: '200px', overflowY: 'auto' }}
              >
                {stationList.length > 0 &&
                  files.map(file => (
                    <Flex
                      alignItems="center"
                      className="py-2 border-bottom btn-reveal-trigger"
                      key={file.path}
                    >
                      {fileTypes.image.includes(
                        getFileExtention(file.name)
                      ) && (
                        <img
                          className="rounded"
                          width={30}
                          height={30}
                          src={file.preview}
                          alt={'document'}
                        />
                      )}

                      {fileTypes.msWord.includes(
                        getFileExtention(file.name)
                      ) && <BsFillFileEarmarkWordFill size={30} />}

                      {fileTypes.msExcel.includes(
                        getFileExtention(file.name)
                      ) && <BsFillFileEarmarkExcelFill size={30} />}

                      {fileTypes.pdf.includes(getFileExtention(file.name)) && (
                        <BsFillFileEarmarkPdfFill size={30} />
                      )}

                      {fileTypes.video.includes(
                        getFileExtention(file.name)
                      ) && <MdVideoLibrary size={30} />}

                      <Flex
                        justifyContent="between"
                        alignItems="center"
                        className="ms-3 flex-1"
                      >
                        <div>
                          <h6 className="m-0">{file.path}</h6>
                          <Flex
                            className="position-relative"
                            alignItems="center"
                          >
                            <p className="mb-0 fs--1 text-400 line-height-1">
                              <strong>{getSize(file.size)}</strong>
                            </p>
                          </Flex>
                        </div>
                      </Flex>
                      <FontAwesomeIcon
                        className="cursor-pointer mr-1"
                        color="#d9534f"
                        icon="trash-alt"
                        onClick={() => handleRemove(file.path)}
                      />
                      {/* <CardDropdown>
                        <div className="py-2">
                          <Dropdown.Item
                            className="text-danger"
                            onClick={() => handleRemove(file.path)}
                          >
                            Remove
                          </Dropdown.Item>
                        </div>
                      </CardDropdown> */}
                    </Flex>
                  ))}
              </div>
              <div className="d-flex align-item-center mt-4">
                <Button
                  disabled={isLoading}
                  variant="falcon-primary"
                  type="submit"
                  className="me-2 w-50"
                  size="sm"
                >
                  Submit
                </Button>
                {isLoading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </Form>
          ) : (
            <> loading... </>
          )}
        </Card.Body>
      </Card>

      {/* Listing uploaded preview */}
      <Card
        bg={'light'}
        className="col-lg-5 col-md-12 p-0"
        style={{ height: 'fit-content' }}
      >
        <Card.Header>
          <h5>Documents Uploaded</h5>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-around align-items-center flex-wrap">
            <div>
              <div> Station </div>
              <Form.Select
                className="cursor-pointer"
                onChange={handleChangeCurrentStation}
                aria-label="Default select example"
                value={currentStation}
              >
                <option value={''}> Select station </option>
                {genrateOption(stations)}
              </Form.Select>
            </div>
            <div>
              <div> Language </div>
              <Form.Select
                className="cursor-pointer"
                onChange={handleChangeLanguageListing}
                aria-label="Default select example"
                value={languageListing}
              >
                <option value="en">English</option>
                <option value="ro">Romania</option>
                <option value="po">Polish</option>
              </Form.Select>
            </div>
          </div>
          <Table hover responsive>
            <thead>
              <tr>
                <th className="col-sm-1" scope="col">
                  Attachment
                </th>
                <th className="col-sm-1" scope="col">
                  Name
                </th>
                <th className="col-sm-1" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {currentStation && languageListing && uploaded.length > 0 ? (
                generateTbody(uploaded)
              ) : (
                <tr>
                  <td
                    colSpan={3}
                    className="pt-4 text-center"
                    style={{ fontWeight: 500 }}
                  >
                    No Document Uploaded
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  ) : (
    <AccessDenied></AccessDenied>
  );
};

export default TrainingDocuments;
