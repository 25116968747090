import {
  swalToast,
  swalToastError,
  swalToastSuccess,
  swalToastWarning
} from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Button, Carousel, Image, Modal } from 'react-bootstrap';
import { getStartUpChecksByMachine } from 'services/machineService';
import '../../assets/scss/user.scss';
import {
  getLastestCheckByMachine,
  logMachineCheck,
  resolveIssueCheck
} from 'services/machineCheckLogService';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { sendEmail } from 'services/mailService';
import { FaArrowRight } from 'react-icons/fa';

export default function StartUpCheck(props) {
  const station = props.station;
  const loginData = props.loginData;
  const manual = props.manual;

  const [checks, setChecks] = useState([]);
  const [currentCheck, setCurrentCheck] = useState({
    checkCode: '',
    checkDescription: '',
    images: []
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalCheckOpen, setIsModalCheckOpen] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [isCorrectQrCode, setIsCorrectQrCode] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [logId, setLogId] = useState(0);
  const tryFocus = () => {
    const input = document.getElementById('qrCodeInput');
    if (input) {
      input.focus();
    }
  };

  useEffect(() => {
    if (!station) return;

    if (props.manual && station.machineId < 1) {
      swalToastWarning('Machine not assigned to this station');
      props.onNoStartUpCheck();
      return;
    }

    if (!loginData || loginData.userId < 1) return;

    if (props.station.machineId < 1) return;

    checkTimeAtStartOfNextHour();

    return () => {
      clearTimeout();
    };
  }, [props.station, props.manual]);

  const checkTimeAtStartOfNextHour = () => {
    const currentTime = new Date();
    const minutes = currentTime.getMinutes();
    let promptStartUpChecks = false;

    getLastestCheckByMachine(station.machineId)
      .then(res => {
        if (res.success && res.data) {
          const lastCheck = res.data;
          if (
            lastCheck &&
            !lastCheck.checkSuccess &&
            !lastCheck.issueResolved
          ) {
            // freeze station
            Swal.fire({
              icon: 'warning',
              title: 'ISSUE REPORT!',
              text: 'Please do NOT use this machine and scan station!',
              confirmButtonText: 'Issue Fixed?',
              confirmButtonColor: '#5cb85c',
              keydownListenerCapture: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              color: '#d9534f',
              iconColor: '#d9534f',
              width: '80vh',
              height: '70vh',
              didOpen: () => {
                setQrCodeValue('');
                Swal.getConfirmButton().focus();
              }
            }).then(result => {
              if (result.isConfirmed) {
                const payload = {
                  logId: lastCheck.logId,
                  machineId: station.machineId,
                  resolvedBy: loginData.userId
                };
                resolveIssueCheck(payload, 'start.up.check')
                  .then(res => {
                    if (res?.success && res.data) {
                      swalToastSuccess(
                        'Issue has been resolved. Starting re-check...'
                      );
                      setChecks(res?.data?.checks);
                      setCurrentCheck(res?.data?.checks[0]);
                      setCurrentStep(0);
                      setIsScanning(false);
                      setIsCorrectQrCode(false);
                      setIsModalCheckOpen(true);
                      props.updateQueue('isShowStatupCheck');
                    }
                  })
                  .catch(error => {
                    swalToastError('Resolved Issue Check Failed!');
                  });
              }
            });
          }

          const hours = currentTime.getHours();
          const lastTimeCheck =
            parseInt(currentTime - new Date(lastCheck.checked)) /
            (1000 * 60 * 60);
          // Check if the current time matches the desired hours
          if (
            (hours === 6 || hours === 14 || hours === 22) &&
            (lastCheck.checkSuccess || lastCheck.issueResolved) &&
            lastTimeCheck >= 7
          ) {
            promptStartUpChecks = true;
          } else {
            promptStartUpChecks = false;
          }

          if (promptStartUpChecks || props.manual) {
            getStartUpChecksByMachine(station.machineId, 'start.up.check')
              .then(async res => {
                if (res?.success && res.data && res.data.length > 0) {
                  setChecks(res.data);
                  setCurrentStep(0);
                  setCurrentCheck(res.data[0]);
                  setIsModalCheckOpen(true);
                  await props.updateQueue('isShowStatupCheck');
                }
              })
              .catch(error => {
                swalToastError('Get Statup Check Failed!');
              });
          }

          // Schedule the next check at the start of the next hour
          if (!props.manual) {
            const nextHour = new Date();
            nextHour.setHours(nextHour.getHours() + 1);
            nextHour.setMinutes(0);
            nextHour.setSeconds(0);
            const timeUntilNextHour = nextHour - currentTime;
            console.log(timeUntilNextHour);
            setTimeout(checkTimeAtStartOfNextHour, timeUntilNextHour);
          }
        }
      })
      .catch(error => {
        swalToastError('Get Latest Machine Check Failed!');
      });
  };

  const handleCheckOK = () => {
    if (!isCorrectQrCode) {
      return;
    }

    if (currentStep < checks.length) {
      if (!qrCodeValue) {
        swalToastError('Please scan QR Code');
        return;
      } else {
        if (
          qrCodeValue.toLocaleLowerCase() !=
          currentCheck.checkCode.toLocaleLowerCase()
        ) {
          swalToastError(`Invalid QR Code`);
          return;
        } else {
          // log check to db
          var payload = {
            stationId: station.id,
            machineId: station.machineId,
            checkId: currentCheck.id,
            checkSuccess: true,
            checkedBy: loginData.userId
          };
          logMachineCheck(payload)
            .then(res => {
              if (res?.success && res.data) {
                let nextStep = currentStep;
                nextStep++;
                if (nextStep >= checks.length) {
                  swalToastSuccess('Start Up Checks Completed');
                  setIsScanning(false);
                  setQrCodeValue('');
                  setIsModalCheckOpen(false);
                  setIsCorrectQrCode(false);
                  handleCloseCheck();
                } else {
                  setCurrentStep(nextStep);
                  setCurrentCheck(checks[nextStep]);
                  setQrCodeValue('');
                  setIsCorrectQrCode(false);
                  setIsScanning(false);
                }
              } else {
                swalToastError('Failed to log check');
              }
            })
            .catch(err => {
              console.log(err);
              swalToastError('Error. Something went wrong');
            });
        }
      }
    } else {
      swalToastSuccess('Start Up Checks Completed');
      setIsModalCheckOpen(false);
    }
  };

  const handleReportIssues = () => {
    var payload = {
      stationId: station.id,
      machineId: station.machineId,
      checkId: currentCheck.id,
      checkSuccess: false,
      checkedBy: loginData.userId
    };
    logMachineCheck(payload)
      .then(res => {
        if (res?.success && res.data) {
          setIsModalCheckOpen(false);
          setChecks([]);
          setCurrentStep(0);
          setLogId(res.data.logId);

          // send email
          const email = {
            subject: 'Machine Issue Report',
            app: 'machine-issue-report',
            // to: 'dawid.hajduk@emplas.co.uk',
            // cc: 'trung.tran@emplas.co.uk, thien.pham@emplas.co.uk, quang.dinh@emplas.co.uk',
            body: `Machine issue reported at ${
              station.stationName
            } station by ${loginData.userName} - ${dayjs().format(
              'DD/MM/YYYY HH:mm'
            )}`
          };

          sendEmail(email)
            .then(res => {
              console.log(res);
            })
            .catch(error => {
              swalToastError('Send Notify Failed!');
            });

          Swal.fire({
            icon: 'warning',
            title: 'ISSUE REPORT!',
            text: 'Please do NOT use this machine and scan station!',
            confirmButtonText: 'Issue Fixed?',
            confirmButtonColor: '#5cb85c',
            keydownListenerCapture: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            color: '#d9534f',
            iconColor: '#d9534f',
            width: '80vh',
            height: '70vh',
            didOpen: () => {
              setQrCodeValue('');
              props.onReportIssue();
              Swal.getConfirmButton().focus();
            }
          }).then(result => {
            if (result.isConfirmed) {
              const payload = {
                logId: res.data.logId,
                machineId: station.machineId,
                resolvedBy: loginData.userId
              };
              resolveIssueCheck(payload, 'start.up.check')
                .then(res => {
                  if (res.success && res.data) {
                    swalToastSuccess(
                      'Issue has been resolved. Starting re-check...'
                    );
                    setChecks(res.data?.checks);
                    setCurrentCheck(res.data?.checks[0]);
                    setCurrentStep(0);
                    setIsScanning(false);
                    setIsCorrectQrCode(false);
                    setIsModalCheckOpen(true);
                  }
                })
                .catch(error => {
                  swalToastError('Resolved Issue Check Failed!');
                });
            }
          });
        } else {
          swalToastError('Failed to log check');
        }
      })
      .catch(err => {
        swalToastError('Error. Something went wrong');
      });
  };

  const handleScan = e => {
    setQrCodeValue(e.target.value);
  };

  const onQrCodeKeyup = e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.key === 'Enter') {
      setIsScanning(true);

      if (isCorrectQrCode) {
        handleCheckOK();
        return;
      }

      if (!qrCodeValue) {
        return;
      } else {
        if (qrCodeValue.toLowerCase() != currentCheck.checkCode.toLowerCase()) {
          setIsCorrectQrCode(false);
          setQrCodeValue('');

          Swal.fire({
            icon: 'error',
            title: 'Incorrect barcode!',
            timer: 3000,
            timerProgressBar: true,
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff',
            width: 1300,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        } else {
          setIsCorrectQrCode(true);
          Swal.fire({
            icon: 'success',
            title: 'Correct barcode!',
            timer: 3000,
            timerProgressBar: true,
            background: '#5cb85c',
            iconColor: '#fff',
            color: '#fff',
            width: 1300,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        }
      }
    }
  };

  const handleCloseCheck = () => {
    console.log('close');
    props.onCheckCompleted();
  };

  useEffect(async () => {
    await props.PopDataOfQueue('isShowStatupCheck', props.setIsShowStatupCheck);
  }, [props.queue]);

  useEffect(() => {
    if (isModalCheckOpen && props.isShowStatupCheck) {
      tryFocus();
    }
  }, [isModalCheckOpen, props.isShowStatupCheck]);

  return (
    <div className="start-up-check-modal">
      <Modal
        keyboard={false}
        // allowEscapeKey={false}
        show={
          isModalCheckOpen &&
          props.isShowStatupCheck &&
          props.queue[props.queue.length - 1] === 'isShowStatupCheck'
        }
        // onKeyDown={handleKeyDown}
        onHide={handleCloseCheck}
        contentClassName="border"
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          className="bg-light px-card border-bottom-0"
          closeButton={null}
        >
          <Modal.Title as="h4">
            Machine Start Up Checks<br></br>
            <small>
              Step: {currentStep + 1}/{checks.length}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '80vh' }}>
          <div>
            <div className="row">
              <div className="col-sm-12">{currentCheck.checkDescription}</div>
            </div>
            <div className="row">
              <div
                className="col-sm-12 d-flex"
                style={{ justifyContent: 'center' }}
              >
                {currentCheck.images.length > 0 ? (
                  <Carousel interval={10000} style={{ height: '55vh' }}>
                    {currentCheck.images.map((img, index) => (
                      <Carousel.Item key={index}>
                        <Image
                          key={`img${img.id}`}
                          src={`${
                            process.env.REACT_APP_FILE_API_HOST
                          }/files/download/${
                            process.env.REACT_APP_BUCKET_NAME
                          }?key=${img.path.replace('s3/eva-files/', '')}`}
                          alt=""
                          fluid
                          //className="check-img"
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            height: 'auto',
                            maxHeight: '55vh'
                          }}
                        ></Image>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row mt-4" style={{ justifyContent: 'center' }}>
              <div className="col-sm-6 d-flex">
                <input
                  type="text"
                  id="qrCodeInput"
                  onBlur={tryFocus}
                  className="form-control"
                  placeholder="Scan QR Code"
                  value={qrCodeValue}
                  onChange={e => handleScan(e)}
                  onKeyUp={e => onQrCodeKeyup(e)}
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isCorrectQrCode ? (
            <span
              className="d-flex blink"
              style={{ alignItems: 'center', marginRight: '20px' }}
            >
              <h4 style={{ marginRight: '10px' }}>
                Please now select the correct option
              </h4>{' '}
              <FaArrowRight
                size={20}
                style={{ marginBottom: '4px' }}
              ></FaArrowRight>
            </span>
          ) : (
            ''
          )}

          <Button
            className="btn btn-primary"
            onClick={handleCheckOK}
            disabled={!isCorrectQrCode}
          >
            OK
          </Button>
          <Button
            className="btn btn-warning"
            onClick={handleReportIssues}
            disabled={!isCorrectQrCode}
          >
            Report Issue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
