import { UpdateModeEnum } from 'chart.js';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import {
  getEnabledClearSash,
  getSashInfo,
  updateEnabledClearSash
} from 'services/sashLocatorService';

export default function SashLocatorWidget({
  barcode,
  permissionId,
  stationId,
  panelId
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [sashLocations, setSashLocation] = useState([]);
  const [isEnableClearSash, setIsEnableClearSash] = useState(true);
  const [isRender, setIsRender] = useState(false);

  const handleClearSash = e => {
    updateEnabledClearSash(panelId, stationId, e.target.checked)
      .then(res => {
        if (res.data && res.success) {
          setIsEnableClearSash(e.target.checked);
        }
      })
      .catch(err => {
        swalToastError('Update Enabled Clear Sash Failed');
      });
  };

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getSashInfo(barcode)
        .then(res => {
          if (res.data && res.success) {
            setSashLocation(res.data);
          }
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Sash Info Failed!');
        });
    }
  }, [barcode]);

  useEffect(() => {
    getEnabledClearSash(panelId, stationId)
      .then(res => {
        if (res.success && res.data) {
          setIsEnableClearSash(res.data);
          setIsRender(true);
        }
      })
      .catch(err => {
        swalToastError('Get Enabled Clear Sash Failed!');
      });
  }, [panelId, stationId, permissionId]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <div>
        {isRender && (
          <Form.Check
            type="switch"
            id="isEnableClearSash"
            label="Enable To Clear Sash Location"
            disabled={permissionId != process.env.REACT_APP_ADMIN_PERMISSION}
            onChange={handleClearSash}
            defaultChecked={isEnableClearSash}
          />
        )}
      </div>
      <div>
        <Table>
          <thead>
            <tr>
              <th>Rack Number</th>
              <th>Slot Number</th>
              <th>Sash No</th>
            </tr>
          </thead>
          <tbody>
            {barcode > 0 && sashLocations.length > 0 ? (
              sashLocations.map((item, index) => (
                <tr key={index}>
                  <td>{item.rackNumber}</td>
                  <td>{item.slotNumber}</td>
                  <td>{item.sashNo}</td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan={3}>No Record Found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}
