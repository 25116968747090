import axios from 'axios';
import axiosHelper from 'helpers/axiosHepler';
import { getEvaUser } from 'helpers/utils';

const loginByBarcode = (barcode, enableUidLogin) => {
  const url = `/Auth/barcode`;
  const data = { barcode, enableUidLogin };
  return axiosHelper.post(url, data);
};

const getUserPermissions = username => {
  const url = `/Auth/permissions?username=${username}`;
  return axiosHelper.get(url);
};

const extendSession = async (userId, sessionId) => {
  const url = `/Auth/extend-session?userId=${userId}&sessionId=${sessionId}`;
  return axiosHelper.post(url);
};

const verifyCookie = async () => {
  const res = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_2_HOST}/auth/isLoggedIn`
  }).catch(err => {
    console.log('ERR', err.response);
    if (err.response.status === 401)
      return { message: 'Unauthorised', success: false };
    else return { message: 'Something went wrong', success: false };
  });

  if (res && res.status === 200) {
    const resPermission = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_2_HOST}/permission`
    }).catch(err => {
      console.log('ERR', err.response);
      if (err.response.status === 401)
        return { message: 'Unauthorised', success: false };
      else return { message: 'Something went wrong', success: false };
    });

    if (resPermission && resPermission.status === 200) {
      const currStore = getEvaUser();
      sessionStorage.setItem(
        'user',
        JSON.stringify({
          ...currStore,
          userId: res.data?.userId,
          isAuthenticated: res.data?.success,
          userEmail: res.data?.email,
          userName: res.data?.name
        })
      );

      const store = getEvaUser();
      sessionStorage.setItem(
        'user',
        JSON.stringify({
          ...store,
          permissions: resPermission.data?.data?.permissionsData,
          isFullPermission: resPermission.data?.data?.isFullPermission
        })
      );

      return {
        message: 'Successful response',
        success: res.data.success,
        userId: res.data.userId
      };
    }
  }
  return { message: 'Faliure response', success: false, userId: 0 };
};

const handleLogout = () => {
  const url = `/Auth/logout`;
  return axiosHelper.get(url);
};

export {
  loginByBarcode,
  getUserPermissions,
  extendSession,
  verifyCookie,
  handleLogout
};
