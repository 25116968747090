import FrameImage from 'components/station/FrameImage';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { CATEGORIES } from 'helpers/utils';
import { React, useEffect, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getEnabledMoveStage } from 'redux/slices/widgetSlice';
import { startWeledCill } from 'services/qcService';

var dayjs = require('dayjs');

export default function WeldedCillDetail({
  barcode,
  startStage,
  userId,
  onScanStarted,
  onScanCompleted,
  groupName,
  setProductCompleted,
  panelSetupId,
  stationId,
  setIsScanning,
  setScannedBarcode
}) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [weldedCill, setWeldedCill] = useState({});
  const [weldedCillPartCodes, setWeldedCillPartCodes] = useState([]);

  useEffect(() => {
    dispatch(getEnabledMoveStage(panelSetupId));
  }, []);

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      const payload = {
        barcode,
        stage: startStage,
        userId,
        filterGroup: groupName
      };
      startWeledCill(payload)
        .then(res => {
          if (res.success && res.data && res.data.weldedCill) {
            setWeldedCill(res.data.weldedCill);
          }
          if (res.success && res.data && res.data.weldedCillPartCodes) {
            setWeldedCillPartCodes(res.data.weldedCillPartCodes);
          }
          setIsLoading(false);
          onScanStarted();
          onScanCompleted(res.data.userTrackingId);
          setProductCompleted(CATEGORIES.BOX);
        })
        .catch(error => {
          swalToastError('Get Welded Cill Detail Failed!');
          setIsLoading(false);
          onScanCompleted(0);
          setIsScanning(false);
          setScannedBarcode(0);
        });
    }
  }, [barcode]);
  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Job No:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">{weldedCill.jobNo}</p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Customer:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">
            {weldedCill.customer}
            <Badge bg="danger">{weldedCill.priorityType}</Badge>
          </p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Date Required:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">
            {weldedCill.requiredDate
              ? dayjs(weldedCill.requiredDate).format('DD/MM/YYYY')
              : ''}
          </p>
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        <FrameImage
          jobNo={weldedCill.jobNo}
          winNo={weldedCill.bayNo}
          isBox={true}
        />
      </div>
      <Row
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1.7fr 0.3fr 0.5fr 0.5fr',
          fontWeight: 'bold'
        }}
      >
        <Col>PCode</Col>
        <Col>Description</Col>
        <Col>Qty</Col>
        <Col>Len1</Col>
        <Col>Len2</Col>
      </Row>
      {weldedCillPartCodes.length > 0 ? (
        weldedCillPartCodes.map((item, index) => (
          <Row
            key={index}
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1.7fr 0.3fr 0.5fr 0.5fr'
            }}
          >
            <Col>{item.pCode}</Col>
            <Col>{item.description}</Col>
            <Col>{item.qTy}</Col>
            <Col>{item?.len1 > 0 && item.len1 + 'w'}</Col>
            <Col>{item?.len2 > 0 && item.len2 + 'w'}</Col>
          </Row>
        ))
      ) : (
        <div>No Record Found</div>
      )}
    </div>
  );
}
