import axiosHelper from 'helpers/axiosHepler';

const sendEmail = payload => {
  const url = `/email/send`;
  const data = payload;
  return axiosHelper.post(url, data, {
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    }
  });
};

export { sendEmail };
