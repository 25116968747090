import classNames from 'classnames';
import Flex from 'components/common/Flex';
import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { getCurrentUser } from 'helpers/utils';
import { React, useEffect, useRef, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import SignaturePad from 'react-signature-canvas';
import { uploadMultiFilesToS3 } from 'services/attachmentService';
import {
  getFrameProductChecking,
  submitNCCheckSheet
} from 'services/frameService';

const NCCheckingValue = {
  OK: 'Ok',
  REJECTED: 'Rejected',
  NAH: 'N/A'
};

const defaultCheckSheet = {
  demensions: NCCheckingValue.NAH,
  demensionsComment: '',
  reinforcement: NCCheckingValue.NAH,
  reinforcementComment: '',
  design: NCCheckingValue.NAH,
  designComment: '',
  beads: NCCheckingValue.NAH,
  beadsComment: '',
  profileColour: NCCheckingValue.NAH,
  profileColourComment: '',
  gaskets: NCCheckingValue.NAH,
  gasketsComment: '',
  drainage: NCCheckingValue.NAH,
  drainageComment: '',
  trickleVent: NCCheckingValue.NAH,
  trickleVentComment: '',
  specialInstruction: NCCheckingValue.NAH,
  specialInstructionComment: '',
  handleType: NCCheckingValue.NAH,
  handleTypeComment: '',
  profileCombinations: NCCheckingValue.NAH,
  profileCombinationsComment: '',
  hingeTypeFittingOperation: NCCheckingValue.NAH,
  hingeTypeFittingOperationComment: '',
  lockTypeFuture: NCCheckingValue.NAH,
  lockTypeFutureComment: '',
  weldingGroovingCleaning: NCCheckingValue.NAH,
  weldingGroovingCleaningComment: '',
  handleTypeOperation: NCCheckingValue.NAH,
  handleTypeOperationComment: '',
  glassUnit: NCCheckingValue.NAH,
  glassUnitComment: '',
  signature: ''
};

export default function ProductChecking({
  barcode,
  queue,
  setQueue,
  updateQueue,
  setIsShowSubmitedProductChecking,
  setIsPassedProductChecking
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [checkSheet, setCheckSheet] = useState();
  const [productDetail, setProductDetail] = useState({});
  const [isDisabledForm, setIsDisabledForm] = useState(true);

  const [files, setFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const signedRef = useRef();

  useEffect(() => {
    setFiles([]);
    setFilesUpload([]);
    if (barcode) {
      setIsLoading(true);
      getFrameProductChecking(barcode)
        .then(res => {
          if (res.success && res.data) {
            if (res.data.frameDetail) {
              setProductDetail({
                ...res.data.frameDetail,
                date: new Date(res.data.frameDetail?.requiredDate)
              });
            }

            if (res.data.nCCheckSheetForm) {
              setCheckSheet(res.data.nCCheckSheetForm);

              //Checking disabled NC Form
              Object.keys(res.data.nCCheckSheetForm).map(key => {
                if (
                  res.data.nCCheckSheetForm[key] == NCCheckingValue.REJECTED
                ) {
                  setIsDisabledForm(false);
                  setIsPassedProductChecking(false);
                }
              });

              // Show Popup warning frame has submited product checking.
              setIsShowSubmitedProductChecking(true);
              updateQueue('isShowSubmitedProductChecking');
            } else {
              setIsDisabledForm(false);
              setCheckSheet(defaultCheckSheet);
            }
          }
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Data Frame Failed With Barcode: ' + barcode);
        });
    }
  }, [barcode]);

  const handleInputChange = e => {
    let value = e.target.value;
    setCheckSheet({ ...checkSheet, [e.target.name]: value });
  };

  const handleRadioChange = e => {
    setCheckSheet({
      ...checkSheet,
      [e.target.name]: e.target.value
    });
  };

  const getSignature = () => {
    if (
      signedRef.current &&
      signedRef.current.getTrimmedCanvas().toDataURL('image/png')
    ) {
      const signatureBase64 = signedRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      setCheckSheet({
        ...checkSheet,
        signature: signatureBase64
      });
      signedRef.current.off();
    }
  };

  const clearSignature = () => {
    signedRef.current.clear();

    setCheckSheet({
      ...checkSheet,
      signature: ''
    });

    signedRef.current.on();
  };

  const handleSubmit = e => {
    e.preventDefault();

    const currentUser = getCurrentUser();

    const payload = [];
    const formData = new FormData();
    if (filesUpload.length > 0) {
      filesUpload.map((item, index) => {
        formData.append('files', item);
      });

      const prefix = `eva-scan-station/product-checking`;
      uploadMultiFilesToS3(formData, prefix)
        .then(res => {
          if (res?.success && res.mappings?.length > 0) {
            res.mappings.map(file => {
              const data = {
                ...checkSheet,
                photo: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}/${file.hashedName}`,
                jobNo: productDetail.jobNo,
                requiredDate: dayjs(productDetail.requiredDate).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                winNo: productDetail.winNo,
                customer: productDetail.mainName,
                location: productDetail.locationA,
                windowHeight: productDetail.windowHeight,
                windowWidth: productDetail.windowWidth,
                createdBy: currentUser.userName
              };
              payload.push(data);
            });
            submitNCCheckSheet(payload)
              .then(res => {
                if (res.success && res.data) {
                  // setCheckSheet(defaultCheckSheet);
                  swalToastSuccess('Add N&C Check Sheet Success');
                }
              })
              .catch(error => {
                swalToastError('Add N&C Check Sheet Failed!');
              });
          }
        })
        .catch(error => {
          swalToastError('Upload file Failed!');
        });
    } else {
      const data = {
        ...checkSheet,
        photo: ``,
        jobNo: productDetail.jobNo,
        requiredDate: dayjs(productDetail.requiredDate).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        winNo: productDetail.winNo,
        customer: productDetail.mainName,
        location: productDetail.locationA,
        windowHeight: productDetail.windowHeight,
        windowWidth: productDetail.windowWidth,
        createdBy: currentUser.userName
      };
      payload.push(data);
      submitNCCheckSheet(payload)
        .then(res => {
          if (res.success && res.data) {
            // setCheckSheet(defaultCheckSheet);
            swalToastSuccess(
              'Add N&C Check Sheet Success And Check Move To Depatch Loading Stage Success!'
            );
          }
        })
        .catch(error => {
          swalToastError(
            'Add N&C Check Sheet Success And Check Move To Depatch Loading Stage Failed!'
          );
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      setFilesUpload([...filesUpload, ...acceptedFiles]);
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  return (
    <>
      {barcode ? (
        isLoading ? (
          <StationSpinner></StationSpinner>
        ) : (
          <Form
            autoComplete="off"
            onSubmit={handleSubmit}
            id="product-checking-form"
          >
            <div>
              <Table style={{ cursor: 'default' }}>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">OK</th>
                    <th scope="col">N/A</th>
                    <th scope="col">Rejected</th>
                    <th scope="col">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Demensions</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="demensions1"
                        name="demensions"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.demensions == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="demensions2"
                        name="demensions"
                        value={NCCheckingValue.NAH}
                        defaultChecked={
                          checkSheet?.demensions == NCCheckingValue.NAH
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="demensions3"
                        name="demensions"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.demensions == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.demensions === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="demensionsComment"
                          type="text"
                          value={checkSheet?.demensionsComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Reinforcement</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="reinforcement1"
                        name="reinforcement"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.reinforcement == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="reinforcement2"
                        name="reinforcement"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.reinforcement == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="reinforcement3"
                        name="reinforcement"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.reinforcement == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.reinforcement ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="reinforcementComment"
                          type="text"
                          value={checkSheet?.reinforcementComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Design</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="design1"
                        name="design"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.design == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="design2"
                        name="design"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.design == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="design3"
                        name="design"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.design == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.design === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="designComment"
                          type="text"
                          value={checkSheet?.designComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Beads</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="beads1"
                        name="beads"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={checkSheet?.beads == NCCheckingValue.OK}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="beads2"
                        name="beads"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.beads == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="beads3"
                        name="beads"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.beads == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.beads === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="beadsComment"
                          type="text"
                          value={checkSheet?.beadsComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Profile Colour</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="profileColour1"
                        name="profileColour"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.profileColour == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="profileColour2"
                        name="profileColour"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.profileColour == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="profileColour3"
                        name="profileColour"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.profileColour == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.profileColour ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="profileColourComment"
                          type="text"
                          value={checkSheet?.profileColourComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Gaskets</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="gaskets1"
                        name="gaskets"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.gaskets == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="gaskets1"
                        name="gaskets"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.gaskets == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="gaskets1"
                        name="gaskets"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.gaskets == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.gaskets === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="gasketsComment"
                          type="text"
                          value={checkSheet?.gasketsComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Drainage</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="drainage"
                        name="drainage"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.drainage == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="drainage2"
                        name="drainage"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.drainage == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="drainage3"
                        name="drainage"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.drainage == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.drainage === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="drainageComment"
                          type="text"
                          value={checkSheet?.drainageComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Trickle Vent</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="trickleVent1"
                        name="trickleVent"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.trickleVent == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="trickleVent2"
                        name="trickleVent"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.trickleVent == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="trickleVent3"
                        name="trickleVent"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.trickleVent == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.trickleVent === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="trickleVentComment"
                          type="text"
                          value={checkSheet?.trickleVentComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Special Instruction</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="specialInstruction1"
                        name="specialInstruction"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.specialInstruction == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="specialInstruction2"
                        name="specialInstruction"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.specialInstruction == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="specialInstruction3"
                        name="specialInstruction"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.specialInstruction ==
                          NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.specialInstruction ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="specialInstructionComment"
                          type="text"
                          value={checkSheet?.specialInstructionComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Handle Type</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="handleType1"
                        name="handleType"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.handleType == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="handleType2"
                        name="handleType"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.handleType == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="handleType3"
                        name="handleType"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.handleType == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.handleType === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="handleTypeComment"
                          type="text"
                          value={checkSheet?.handleTypeComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Profile Combinations</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="profileCombinations1"
                        name="profileCombinations"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.profileCombinations == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="profileCombinations2"
                        name="profileCombinations"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.profileCombinations == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="profileCombinations3"
                        name="profileCombinations"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.profileCombinations ==
                          NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.profileCombinations ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="profileCombinationsComment"
                          type="text"
                          value={checkSheet?.profileCombinationsComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Hinge type, Fitting Operation</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="hingeTypeFittingOperation1"
                        name="hingeTypeFittingOperation"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.hingeTypeFittingOperation ==
                          NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="hingeTypeFittingOperation2"
                        name="hingeTypeFittingOperation"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.hingeTypeFittingOperation ==
                          NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="hingeTypeFittingOperation3"
                        name="hingeTypeFittingOperation"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.hingeTypeFittingOperation ==
                          NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.hingeTypeFittingOperation ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="hingeTypeFittingOperationComment"
                          type="text"
                          value={checkSheet?.hingeTypeFittingOperationComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Lock Type & Furniture</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="lockTypeAndFuture1"
                        name="lockTypeFuture"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.lockTypeFuture == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="lockTypeAndFuture2"
                        name="lockTypeFuture"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.lockTypeFuture == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="lockTypeAndFuture3"
                        name="lockTypeFuture"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.lockTypeFuture == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.lockTypeFuture ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="lockTypeFutureComment"
                          type="text"
                          value={checkSheet?.lockTypeFutureComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Welding, Grooving & Cleaning</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="weldingGroovingAndCleaning1"
                        name="weldingGroovingCleaning"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.weldingGroovingCleaning ==
                          NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="weldingGroovingAndCleaning2"
                        name="weldingGroovingCleaning"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.weldingGroovingCleaning ==
                          NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="weldingGroovingAndCleaning3"
                        name="weldingGroovingCleaning"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.weldingGroovingCleaning ==
                          NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.weldingGroovingCleaning ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="weldingGroovingCleaningComment"
                          type="text"
                          value={checkSheet?.weldingGroovingCleaningComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Handle Type & Operaion</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="handleTypeAndOperation1"
                        name="handleTypeOperation"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.handleTypeOperation == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="handleTypeAndOperation2"
                        name="handleTypeOperation"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.handleTypeOperation == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="handleTypeAndOperation3"
                        name="handleTypeOperation"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.handleTypeOperation ==
                          NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.handleTypeOperation ===
                        NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="handleTypeOperationComment"
                          type="text"
                          value={checkSheet?.handleTypeOperationComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Glass Unit</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="glassUnit1"
                        name="glassUnit"
                        value={NCCheckingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.glassUnit == NCCheckingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="glassUnit2"
                        name="glassUnit"
                        value={NCCheckingValue.NAH}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.glassUnit == NCCheckingValue.NAH
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="glassUnit3"
                        name="glassUnit"
                        value={NCCheckingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          checkSheet?.glassUnit == NCCheckingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {checkSheet?.glassUnit === NCCheckingValue.REJECTED && (
                        <Form.Control
                          name="glassUnitComment"
                          type="text"
                          value={checkSheet?.glassUnitComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Taking Photos</td>
                    <td colSpan={3}>
                      <div
                        {...getRootProps({
                          className: `${classNames(`dropzone-area py-1`)}`
                        })}
                      >
                        <input
                          name="photoUpload"
                          {...getInputProps()}
                          capture="environment"
                        />
                        <Flex justifyContent="center" alignItems="center">
                          <p className="fs-0 mb-0 text-700">Taking Photos</p>
                        </Flex>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {files.map((file, index) => (
                          <Flex alignItems="center" key={index}>
                            <img
                              className="rounded"
                              width={50}
                              height={50}
                              src={file.preview}
                              alt={'document'}
                            />
                          </Flex>
                        ))}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Sign off by:</td>
                    <td colSpan={3}>
                      <div style={{ cursor: 'default' }}>
                        <SignaturePad
                          canvasProps={{ width: 300, height: 100 }}
                          backgroundColor="#a2a2a2"
                          ref={signedRef}
                        />
                      </div>
                      <div>
                        <Button variant="info" onClick={getSignature}>
                          Get signature
                        </Button>
                        <Button variant="secondary " onClick={clearSignature}>
                          Clear
                        </Button>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td colSpan={3}>
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isDisabledForm}
                      >
                        Submit
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Form>
        )
      ) : (
        <h3>Please Scan Barcode</h3>
      )}
    </>
  );
}
