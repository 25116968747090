import { React, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useState } from 'react';
import { checkGlassGeorgianImgPath } from 'services/glassService';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';

export default function GlassGeorgianWidget(props) {
  let barcode = props.barcode;

  const [imgPath, setImgPath] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);

      checkGlassGeorgianImgPath(barcode)
        .then(res => {
          if (res?.success && res.data) {
            if (!res?.data?.missing) {
              setImgPath(
                `${process.env.REACT_APP_API_HOST}/Panel/georgian-glass-image?imgPath=${res?.data?.path}`
              );
            }
          }
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Georgian Glass Image Faield!.');
        });
    }
  }, [barcode]);

  console.log(imgPath);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      {imgPath ? (
        <Image
          style={{
            objectFit: 'contain',
            maxWidth: '450px',
            maxHeight: '450px'
          }}
          src={imgPath}
          className="center-block"
          width={450}
          height={450}
          alt="georgian lead image"
          unoptimized="true"
        />
      ) : (
        ''
      )}
    </>
  );
}
