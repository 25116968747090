import { React, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useState } from 'react';
import { getGlassPartItems } from 'services/partService';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';

export default function GlassPartsWidget(props) {
  let barcode = props.barcode;
  let groupName = props.groupName;

  const [glassPartItems, setGlassPartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let glassPartItemRows = [];

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);

      getGlassPartItems(barcode, groupName)
        .then(res => {
          if (res.data && res.success) {
            setGlassPartItems(res.data?.glassPartItems);
          }

          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Glass Part Items Failed!');
        });
    } else {
      setGlassPartItems([]);
    }
  }, [barcode]);

  if (glassPartItems?.length > 0) {
    glassPartItems.forEach(function (item, index) {
      glassPartItemRows.push(
        <tr key={'row' + index}>
          <td>{item.qty}</td>
          <td>{item.description}</td>
          <td>{item.width}</td>
          <td>{item.height}</td>
          <td>{item.location}</td>
        </tr>
      );
    });
  } else {
    glassPartItemRows.push(
      <tr key={'row0'}>
        <td colSpan={3}>{'No records found.'}</td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Qty</th>
            <th>Description</th>
            <th>W</th>
            <th>H</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>{glassPartItemRows}</tbody>
      </Table>
    </>
  );
}
