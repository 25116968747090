import axiosHelper from 'helpers/axiosHepler';

const getOptionsByKey = key => {
  const url = `/option-lists-management/${key}`;
  return axiosHelper.get(url);
};

const createOption = payload => {
  const url = `/option-lists-management`;
  const data = payload;
  return axiosHelper.post(url, data);
};

const updateOptionName = payload => {
  const url = `/option-lists-management/option-name`;
  const data = payload;
  return axiosHelper.patch(url, data);
};

const deleteOption = optionId => {
  const url = `/option-lists-management/${optionId}`;
  return axiosHelper.delete(url);
};

export { getOptionsByKey, createOption, updateOptionName, deleteOption };
