import axiosHelper from 'helpers/axiosHepler';

const getQualityChecksByStation = stationId => {
  const url = `/station-quality-checks?stationId=${stationId}`;
  return axiosHelper.get(url);
};

const getQualityCheckById = checkId => {
  const url = `/station-quality-checks/${checkId}`;
  return axiosHelper.get(url);
};

const getQualityCheckStepsByCheck = (checkId, app) => {
  const url = `/station-quality-checks/${checkId}/steps?app=${app}`;
  return axiosHelper.get(url);
};

const createStationQc = qcItem => {
  const url = `/station-quality-checks`;
  const data = qcItem;
  return axiosHelper.post(url, data);
};

const updateStationQc = qcItem => {
  const url = `/station-quality-checks`;
  const data = qcItem;
  return axiosHelper.post(url, data);
};

const deleteStationQc = checkId => {
  const url = `/station-quality-checks/${checkId}`;
  return axiosHelper.delete(url);
};

const deleteStationQcStepsByCheck = checkId => {
  const url = `/station-quality-checks/${checkId}/steps`;
  return axiosHelper.delete(url);
};

const createStationQcStep = stepItem => {
  const url = ``;
  const data = stepItem;
  return axiosHelper.post(url, data);
};

const updateStationQcStep = step => {
  const url = `/station-quality-checks/step`;
  const data = step;
  return axiosHelper.patch(url, data);
};

const deleteStationQcStep = stepId => {
  const url = `/station-quality-checks/step/${stepId}`;
  return axiosHelper.delete(url);
};

const getUserScanNoFromLastQc = (userId, qcId) => {
  const url = `/station-quality-checks/scan-no?userId=${userId}&qcId=${qcId}`;
  return axiosHelper.get(url);
};

const updateTrackingQc = (userTrackingId, qcId) => {
  const url = `/station-quality-checks/tracking?userTrackingId=${userTrackingId}&qcId=${qcId}`;
  return axiosHelper.post(url);
};

export {
  createStationQc,
  createStationQcStep,
  deleteStationQc,
  deleteStationQcStep,
  deleteStationQcStepsByCheck,
  getQualityCheckById,
  getQualityCheckStepsByCheck,
  getQualityChecksByStation,
  getUserScanNoFromLastQc,
  updateStationQc,
  updateStationQcStep,
  updateTrackingQc
};
