import { React, useEffect } from 'react';
import { Table, Image } from 'react-bootstrap';
import { useState } from 'react';
import { getPartItems } from 'services/partService';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';

export default function PartsWidget(props) {
  let barcode = props.barcode;
  let groupName = props.groupName;

  const [partItems, setPartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let partItemRows = [];

  useEffect(() => {
    if (barcode != 0) {
      setIsLoading(true);
      getPartItems(barcode, groupName)
        .then(async res => {
          if (res.data && res.success) {
            setIsLoading(false);
            if (partItems.length > 0 && res.data.partItems?.length > 0) {
              let finalArray = partItems.map(function (el) {
                return el.pCode;
              });

              let diff = res.data.partItems.filter(el => {
                return !finalArray.includes(el.pCode);
              });

              if (diff.length > 0) {
                await props.updateQueue('isCodeChanged');
                await props.setIsCodeChanged(true);
                props.setDiff(
                  diff.map(function (el) {
                    return el.pCode;
                  })
                );
              } else {
                props.setIsCodeChanged(false);
                props.setDiff([]);
              }
            }
            await setPartItems(res.data.partItems);
          }
        })
        .catch(err => {
          swalToastError('Get Parts item Failed!');
        });
    } else {
      setPartItems([]);
    }
  }, [barcode]);

  if (partItems?.length > 0) {
    partItems.forEach(function (item, index) {
      let textDecorationCss = '';

      if (item.fontUnderline && item.fontStrikeOut) {
        textDecorationCss = 'underline line-through';
      } else {
        if (item.fontUnderline) textDecorationCss = 'underline';
        else if (item.fontStrikeOut) textDecorationCss = 'line-through';
      }

      partItemRows.push(
        <tr
          key={'row' + index}
          style={{
            color: item.fontColour,
            backgroundColor: item.bkColour,
            fontWeight: item.fontBold ? 'bold' : '',
            fontSize: item.fontSize,
            fontStyle: item.fontItalic ? 'italic' : '',
            textDecoration: textDecorationCss
          }}
        >
          <td>{item.qty}</td>
          <td>{item.pCode}</td>
          <td>{item.description}</td>
        </tr>
      );
    });
  } else {
    partItemRows.push(
      <tr key={'row0'}>
        <td colSpan={3}>{'No records found.'}</td>
      </tr>
    );
  }

  //console.log(isLoading);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Qty</th>
            <th>P/Code</th>
            <th>Description</th>
            {/* <th>Image</th> */}
          </tr>
        </thead>
        <tbody>{partItemRows}</tbody>
      </Table>
    </>
  );
}
