import axiosHelper from 'helpers/axiosHepler';

const getFilterGroups = () => {
  const url = `/Filter/filter-groups`;
  return axiosHelper.get(url);
};

const getMergedFilterGroups = groupName => {
  const url = `/Filter/filter-groups-merge-with?groupName=${groupName}`;
  return axiosHelper.get(url);
};

const getFilterItemsByGroup = groupName => {
  const url = `/Filter/filter-items?groupName=${groupName}`;
  return axiosHelper.get(url);
};

const getProductImagesByGroup = (groupName, app) => {
  const url = `/Filter/product-images?filterGroup=${groupName}&app=${app}`;
  return axiosHelper.get(url);
};

const getPartMatchFields = () => {
  const url = `/Filter/match-fields`;
  return axiosHelper.get(url);
};

const getMatchFieldByGroup = groupName => {
  const url = `/Filter/group-match-field?groupName=${groupName}`;
  return axiosHelper.get(url);
};

const updateGroupMatchField = payload => {
  const url = `/Filter/group-match-field`;
  const data = payload;
  return axiosHelper.patch(url, data);
};

const insertFilterItemSetup = item => {
  const url = `/Filter/filter-item`;
  const data = item;
  return axiosHelper.post(url, data);
};

const updateFilterItemsSetup = items => {
  const url = `/Filter/filter-items`;
  const data = items;
  return axiosHelper.post(url, data);
};

const updateMergeFilterGroup = (group, mergeGroup, action) => {
  const url = `/Filter/merge-filter-group?groupName=${group}&mergeGroup=${mergeGroup}&action=${action}`;
  return axiosHelper.post(url);
};

const deleteFilterItemById = id => {
  const url = `/Filter/filter-item/${id}`;
  return axiosHelper.delete(url);
};

const getGroupExcludeOption = groupName => {
  const url = `/Filter/group-exclude-option?groupName=${groupName}`;
  return axiosHelper.get(url);
};

const updateGroupExcludeOption = payload => {
  const url = `/Filter/group-exclude-option`;
  const data = payload;
  return axiosHelper.patch(url, data);
};

export {
  deleteFilterItemById,
  getFilterGroups,
  getFilterItemsByGroup,
  getGroupExcludeOption,
  getMatchFieldByGroup,
  getMergedFilterGroups,
  getPartMatchFields,
  getProductImagesByGroup,
  insertFilterItemSetup,
  updateFilterItemsSetup,
  updateGroupExcludeOption,
  updateGroupMatchField,
  updateMergeFilterGroup
};
