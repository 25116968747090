import axios from 'axios';
import axiosHelper from 'helpers/axiosHepler';

const getFrameImageSizeSetting = async panelSetupId => {
  const url = `/EvaScanStation/frame-image-size?panelSetupId=${panelSetupId}`;
  return axiosHelper.get(url);
};

const getShowSizePanelSetup = async panelSetupId => {
  const url = `/EvaScanStation/show-size?panelSetupId=${panelSetupId}`;
  return axiosHelper.get(url);
};

const getEnabledMoveStageState = async panelSetupId => {
  const url = `/EvaScanStation/enable-move-stage?panelSetupId=${panelSetupId}`;
  return axiosHelper.get(url);
};

const updateFrameImageSizeSetting = async (
  panelSetupId,
  title,
  colour,
  filterGroup,
  payload
) => {
  const url = `/EvaScanStation/frame-image-size?panelSetupId=${panelSetupId}&title=${title}&colour=${colour}&filterGroup=${filterGroup}`;
  const data = payload;
  return axiosHelper.patch(url, data);
};

export {
  getFrameImageSizeSetting,
  updateFrameImageSizeSetting,
  getShowSizePanelSetup,
  getEnabledMoveStageState
};
