import { React, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { getBatchInfo } from 'services/cuttingService';
import StationSpinner from './common/Spinner';
import { getSawCuttingProduct } from 'redux/slices/widgetSlice';
import { useDispatch } from 'react-redux';

export default function SawCuttingSettingPopup({
  isShowSawCuttingSetting,
  setIsShowSawCuttingSetting,
  PopDataOfQueue,
  queue,
  updateQueue,
  barcode
}) {
  const dispatch = useDispatch();

  useEffect(async () => {
    await PopDataOfQueue('isShowSawCuttingSetting', setIsShowSawCuttingSetting);
  }, [queue]);

  const [isLoading, setIsLoading] = useState(false);
  const [batchInfo, setBatchInfo] = useState({});
  const [product, setProduct] = useState(1);

  const handleClose = async () => {
    dispatch(getSawCuttingProduct({ product }));

    setIsShowSawCuttingSetting(false);
    const newQueue = queue.filter(item => item != 'isShowSawCuttingSetting');
    await updateQueue(newQueue);
  };

  useEffect(() => {
    if (isShowSawCuttingSetting) {
      setIsLoading(true);
      getBatchInfo(barcode)
        .then(res => {
          setIsLoading(false);
          if (res.success && res.data) {
            setBatchInfo(res.data);
          }
        })
        .catch(error => {
          setIsLoading(false);
          setBatchInfo({});
        });
    }
  }, [isShowSawCuttingSetting]);

  const onChangeProfileRadio = e => {
    setProduct(+e.target.value);
  };

  return (
    <Modal show={isShowSawCuttingSetting} size="md">
      <Modal.Header>
        <Modal.Title>
          <h3>Saw Cutting Widget Setting</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px'
              }}
            >
              <h4>Batch - {batchInfo.batchNo}</h4>
              <h4>SubBatch - {batchInfo.subBatchNo}</h4>
            </div>
            <div>
              <Form.Check
                label="Frames"
                name="profile"
                type="radio"
                id={`profile1`}
                value={1}
                defaultChecked={product === 1}
                onChange={onChangeProfileRadio}
              />

              <Form.Check
                label="Sashes"
                name="profile"
                type="radio"
                id={`profile2`}
                value={2}
                defaultChecked={product === 2}
                onChange={onChangeProfileRadio}
              />

              <Form.Check
                label="Frames And Sashes"
                name="profile"
                type="radio"
                id={`profile3`}
                value={3}
                defaultChecked={product === 3}
                onChange={onChangeProfileRadio}
              />
            </div>
          </>
        ) : (
          <StationSpinner />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
