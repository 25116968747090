import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getPartItemsV3 } from 'services/partService';
import { getScrewsProfileByPcodes } from 'services/screwsMatrixesService';

export default function PartsWidgetV10(props) {
  const [partItems, setPartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.barcode != 0) {
      setIsLoading(true);
      getPartItemsV3(props.barcode, props.groupName)
        .then(res => {
          if (res.success && res.data && res.data?.partItems.length > 0) {
            const pCodes = res.data?.partItems.map(item => item.pCode);
            if (pCodes && pCodes.length > 1) {
              pCodes.join(',');
            }
            getScrewsProfileByPcodes(pCodes)
              .then(r => {
                if (r.success && r.data) {
                  transformProfileData(res.data?.partItems, r.data);
                }
              })
              .catch(error => {
                swalToastError('Get Number Of Screws Data Failed!');
              });
          }
          setPartItems(res.data?.partItems);
          setIsLoading(false);
        })
        .catch(err => {
          swalToastError('Get Part items Failed!');
        });
    } else {
      setPartItems([]);
    }
  }, [props.barcode]);

  //mapping profile to get total Screws
  const transformProfileData = (partItems, screwsItems) => {
    if (screwsItems.length == 0) {
      setPartItems(partItems);
      return;
    }
    const result = partItems.map((item, ind) => {
      const screw = screwsItems.find(s => s.pCode == item.pCode);
      if (screw) {
        item.screws = +screw.screwsPutInOnHanging * +item.qty;
      }
      return { ...item };
    });

    if (result.length > 0) {
      setPartItems(result);
    }
  };

  const getTextDecorationStyle = item => {
    if (item.fontUnderline && item.fontStrikeOut) {
      return 'underline line-through';
    } else {
      if (item.fontUnderline) return 'underline';
      else if (item.fontStrikeOut) return 'line-through';
    }
  };

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Qty</th>
            <th>P/Code</th>
            <th>Description</th>
            <th>Type</th>
            <th>Screws</th>
          </tr>
        </thead>
        <tbody>
          {partItems.length > 0 ? (
            partItems.map((item, index) => (
              <tr
                key={'row' + index}
                style={{
                  color: item.fontColour,
                  backgroundColor: item.bkColour,
                  fontWeight: item.fontBold ? 'bold' : '',
                  fontSize: item.fontSize,
                  fontStyle: item.fontItalic ? 'italic' : '',
                  textDecoration: getTextDecorationStyle(item)
                }}
              >
                <td style={{ textAlign: 'right' }}>{item.qty}</td>
                <td>{item.pCode}</td>
                <td>{item.description}</td>
                <td>{item.typeDesc}</td>
                <td style={{ textAlign: 'right' }}>{item.screws}</td>
              </tr>
            ))
          ) : (
            <tr key={'row0'}>
              <td colSpan={3}>{'No records found.'}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
