import classNames from 'classnames';
import LoginForm from 'components/authentication/LoginForm';
import Footer from 'components/footer/Footer';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import { swalToastError } from 'helpers/component-utils';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { getPermission } from 'redux/slices/permissionSlice';
import { handleLogout, loginByBarcode } from 'services/userService';
import AuthCardLayout from './AuthCardLayout';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const [loginData, setLoginData] = useState({
    isLogged: false,
    userId: 0,
    userName: 'Not Logged In',
    realName: 'N A'
  });
  const [loginCompleted, setLoginCompleted] = useState(true);

  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  const keyMap = {
    EV_LOGOUT: 'ctrl+alt+0'
  };

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: function () {}
  });

  const keyMapHandlers = {
    EV_LOGOUT: event => {
      localStorage.clear();

      setLoginData({
        isLogged: false,
        userId: 0,
        userName: 'Not Logged In',
        realName: 'N A'
      });
    }
  };

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleTabClose = event => {
      event.stopPropagation();
      logout();
      return;
    };
    window.addEventListener('unload', handleTabClose);
    return () => {
      window.removeEventListener('unload ', handleTabClose);
    };
  }, []);

  const logout = () => {
    localStorage.removeItem('login-data');
    //Clear the cookies when logout
    handleLogout()
      .then(res => {
        console.log('Logout Success!');
      })
      .catch(error => {
        console.log('Logout Failed!');
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);

    let loggedData = localStorage.getItem('login-data');

    if (loggedData) {
      setLoginData({ ...JSON.parse(loggedData) });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleLogin = loginCode => {
    setLoginCompleted(false);

    loginByBarcode(loginCode, true)
      .then(res => {
        if (res.success && res.data) {
          if (res.data?.user) {
            if (!res.data?.permission || !res.data?.permission.isGranted) {
              swalToastError('No access granted');
              setLoginCompleted(true);
              return;
            }
            const loggedData = {
              isLogged: true,
              permissionId: res.data?.permission.permissionId,
              loginType: res.data?.loginType,
              ...res.data?.user
            };

            setLoginData(prevState => {
              return Object.assign({}, prevState, loggedData);
            });

            localStorage.setItem('login-data', JSON.stringify(loggedData));
            dispatch(getPermission(res.data?.permission.permissionId));
          } else {
            swalToastError('User not found');
          }
        }

        setLoginCompleted(true);
      })
      .catch(err => {
        console.log(err);
        swalToastError('Login Failed! Please try again.');
        setLoginCompleted(true);
      });
  };

  return (
    <div className={'container-fluid'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      {loginData.userId > 0 ? (
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop loginData={loginData} />
          <Outlet />
          <Footer />
        </div>
      ) : (
        <AuthCardLayout>
          <h3>Login with UID or RFID</h3>
          <LoginForm
            layout="card"
            login={handleLogin}
            loginCompleted={loginCompleted}
          />
        </AuthCardLayout>
      )}
      <GlobalHotKeys keyMap={keyMap} handlers={keyMapHandlers} />
    </div>
  );
};

export default MainLayout;
