import classNames from 'classnames';
import Flex from 'components/common/Flex';
import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { getCurrentUser } from 'helpers/utils';
import { React, useEffect, useRef, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import SignaturePad from 'react-signature-canvas';
import { uploadMultiFilesToS3 } from 'services/attachmentService';
import { addStillageChecking, getStillageByBarcode } from 'services/qcService';

const checkingValue = {
  OK: 'Ok',
  REJECTED: 'Rejected',
  NAH: 'N/A'
};

const defaultCheckSheet = {
  isWrappedCorrectly: checkingValue.NAH,
  isWrappedCorrectlyComment: '',
  isTheBandingOK: checkingValue.NAH,
  isTheBandingOKComment: '',
  stackingOfUnit: checkingValue.NAH,
  stackingOfUnitComment: '',
  cardBoardPlacement: checkingValue.NAH,
  cardBoardPlacementComment: '',
  damagedUnit: checkingValue.NAH,
  damagedUnitComment: '',
  conditionOfStillage: checkingValue.NAH,
  conditionOfStillageComment: '',
  markedUnits: checkingValue.NAH,
  markedUnitsComment: '',
  bluePadPositioning: checkingValue.NAH,
  bluePadPositioningComment: '',
  signature: '',
  photo: ''
};

export default function StillageChecking({
  barcode,
  updateQueue,
  setIsShowSubmitedProductChecking,
  setIsPassedProductChecking
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [stillageChecking, setStillageChecking] = useState(defaultCheckSheet);
  const [stillage, setStillage] = useState({});
  const [isDisabledForm, setIsDisabledForm] = useState(true);

  const [files, setFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const signedRef = useRef();

  const handleInputChange = e => {
    let value = e.target.value;
    setStillageChecking({ ...stillageChecking, [e.target.name]: value });
  };

  const handleRadioChange = e => {
    setStillageChecking({
      ...stillageChecking,
      [e.target.name]: e.target.value
    });
  };

  const clearSignature = () => {
    signedRef.current.clear();

    setStillageChecking({
      ...stillageChecking,
      signature: ''
    });

    signedRef.current.on();
  };

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getStillageByBarcode(barcode)
        .then(res => {
          if (
            res &&
            res?.data &&
            res?.data.stillageNo &&
            res?.data.stillageId
          ) {
            setStillage({
              stillageNo: res?.data.stillageNo,
              stillageId: res?.data.stillageId
            });
          }
          if (res && res?.data && res?.data?.stillageSubmited) {
            setStillageChecking(res.data.stillageSubmited);
            Object.keys(res.data.stillageSubmited).map(key => {
              if (res.data.stillageSubmited[key] == checkingValue.REJECTED) {
                setIsDisabledForm(false);
              }
            });
          } else {
            setIsDisabledForm(false);
          }
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Welded Cill Failed!');
        });
    }
  }, [barcode]);

  const handleSubmit = e => {
    e.preventDefault();

    const currentUser = getCurrentUser();

    let signature = '';
    if (
      signedRef.current &&
      signedRef.current.getTrimmedCanvas().toDataURL('image/png')
    ) {
      const signatureBase64 = signedRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      signature = signatureBase64;
    }

    const payload = [];
    const formData = new FormData();
    if (filesUpload.length > 0) {
      filesUpload.map((item, index) => {
        formData.append('files', item);
      });

      const prefix = `eva-scan-station/stillage-checking`;
      uploadMultiFilesToS3(formData, prefix)
        .then(res => {
          if (res?.success && res.mappings?.length > 0) {
            res.mappings.map(file => {
              const data = {
                ...stillageChecking,
                photo: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}/${file.hashedName}`,
                stillageId: stillage.stillageId,
                stillageNo: stillage.stillageNo,
                createdBy: currentUser.userId,
                signature: signature,
                jobNo: stillage.jobNo,
                winno: stillage.winNo,
                requiredDate: new Date(stillage.requiredDate)
              };
              payload.push(data);
            });
            addStillageChecking(payload)
              .then(res => {
                if (res.success && res.data) {
                  swalToastSuccess('Add Stillage Checking Success!');
                }
              })
              .catch(error => {
                swalToastError('Add Stillage Checking Failed!');
              });
          }
        })
        .catch(error => {
          swalToastError('Upload file Failed!');
        });
    } else {
      const data = {
        ...stillageChecking,
        photo: ``,
        stillageId: stillage.stillageId,
        stillageNo: stillage.stillageNo,
        createdBy: currentUser.userId,
        signature: signature,
        jobNo: stillage.jobNo,
        winno: stillage.winNo,
        requiredDate: dayjs(stillage.requiredDate).format('YYYY-MM-DD HH:mm:ss')
      };
      payload.push(data);
      addStillageChecking(payload)
        .then(res => {
          if (res.success && res.data) {
            swalToastSuccess('Add Stillage Checking Success!');
          }
        })
        .catch(error => {
          swalToastError('Add Stillage Checking Failed!');
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      setFilesUpload([...filesUpload, ...acceptedFiles]);
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  return (
    <>
      {barcode ? (
        isLoading ? (
          <StationSpinner></StationSpinner>
        ) : (
          <Form
            autoComplete="off"
            onSubmit={handleSubmit}
            id="product-checking-form"
          >
            <div>
              <Table style={{ cursor: 'default' }}>
                <thead>
                  <tr>
                    <th scope="col">Check</th>
                    <th scope="col">OK</th>
                    <th scope="col">N/A</th>
                    <th scope="col">Rejected</th>
                    <th scope="col">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Is the stillage wrapped correctly</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="isWrappedCorrectly1"
                        name="isWrappedCorrectly"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.isWrappedCorrectly ==
                          checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="isWrappedCorrectly2"
                        name="isWrappedCorrectly"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.isWrappedCorrectly ==
                            checkingValue.NAH ||
                          !stillageChecking.isWrappedCorrectly
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="isWrappedCorrectly3"
                        name="isWrappedCorrectly"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.isWrappedCorrectly ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.isWrappedCorrectly ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="isWrappedCorrectlyComment"
                          type="text"
                          value={stillageChecking?.isWrappedCorrectlyComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Is the banding OK</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="isTheBandingOK1"
                        name="isTheBandingOK"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.isTheBandingOK == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="isTheBandingOK2"
                        name="isTheBandingOK"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.isTheBandingOK ==
                            checkingValue.NAH ||
                          !stillageChecking.isTheBandingOK
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="isTheBandingOK3"
                        name="isTheBandingOK"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.isTheBandingOK ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.isTheBandingOK ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="isTheBandingOKComment"
                          type="text"
                          value={stillageChecking?.isTheBandingOKComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Stacking of Units</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="stackingOfUnit1"
                        name="stackingOfUnit"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.stackingOfUnit == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="stackingOfUnit2"
                        name="stackingOfUnit"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.stackingOfUnit ==
                            checkingValue.NAH ||
                          !stillageChecking?.stackingOfUnit
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="stackingOfUnit3"
                        name="stackingOfUnit"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.stackingOfUnit ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.stackingOfUnit ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="stackingOfUnitComment"
                          type="text"
                          value={stillageChecking?.stackingOfUnitComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Cardboard Placement</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cardBoardPlacement1"
                        name="cardBoardPlacement"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.cardBoardPlacement ==
                          checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cardBoardPlacement2"
                        name="cardBoardPlacement"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.cardBoardPlacement ==
                            checkingValue.NAH ||
                          !stillageChecking?.cardBoardPlacement
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cardBoardPlacement3"
                        name="cardBoardPlacement"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.cardBoardPlacement ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.cardBoardPlacement ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="cardBoardPlacementComment"
                          type="text"
                          value={stillageChecking?.cardBoardPlacementComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Damaged Units</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="damagedUnit1"
                        name="damagedUnit"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.damagedUnit == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="damagedUnit2"
                        name="damagedUnit"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.damagedUnit == checkingValue.NAH ||
                          !stillageChecking.damagedUnit
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="damagedUnit3"
                        name="damagedUnit"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.damagedUnit ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.damagedUnit ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="damagedUnitComment"
                          type="text"
                          value={stillageChecking?.damagedUnitComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Condition of Stillage</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="conditionOfStillage1"
                        name="conditionOfStillage"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.conditionOfStillage ==
                          checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="conditionOfStillage2"
                        name="conditionOfStillage"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.conditionOfStillage ==
                            checkingValue.NAH ||
                          !stillageChecking.conditionOfStillage
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="conditionOfStillage3"
                        name="conditionOfStillage"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.conditionOfStillage ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.conditionOfStillage ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="conditionOfStillageComment"
                          type="text"
                          value={stillageChecking?.conditionOfStillageComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Marked units</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="markedUnits1"
                        name="markedUnits"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.markedUnits == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="markedUnits2"
                        name="markedUnits"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.markedUnits == checkingValue.NAH ||
                          !stillageChecking?.markedUnits
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="markedUnits3"
                        name="markedUnits"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.markedUnits ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.markedUnits ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="markedUnitsComment"
                          type="text"
                          value={stillageChecking?.markedUnitsComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Blue Pad Positioning</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="bluePadPositioning1"
                        name="bluePadPositioning"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.bluePadPositioning ==
                          checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="bluePadPositioning2"
                        name="bluePadPositioning"
                        value={checkingValue.NAH}
                        defaultChecked={
                          stillageChecking?.bluePadPositioning ==
                            checkingValue.NAH ||
                          !stillageChecking.bluePadPositioning
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="bluePadPositioning3"
                        name="bluePadPositioning"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          stillageChecking?.bluePadPositioning ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {stillageChecking?.bluePadPositioning ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="bluePadPositioningComment"
                          type="text"
                          value={stillageChecking?.bluePadPositioningComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Taking Photos</td>
                    <td colSpan={3}>
                      <div
                        {...getRootProps({
                          className: `${classNames(`dropzone-area py-1`)}`
                        })}
                      >
                        <input
                          name="photoUpload"
                          {...getInputProps()}
                          capture="environment"
                        />
                        <Flex justifyContent="center" alignItems="center">
                          <p className="fs-0 mb-0 text-700">Taking Photos</p>
                        </Flex>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {files.map((file, index) => (
                          <Flex alignItems="center" key={index}>
                            <img
                              className="rounded"
                              width={50}
                              height={50}
                              src={file.preview}
                              alt={'document'}
                            />
                          </Flex>
                        ))}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Sign off by:</td>
                    <td colSpan={3}>
                      <div style={{ cursor: 'default' }}>
                        <SignaturePad
                          canvasProps={{ width: 300, height: 100 }}
                          backgroundColor="#a2a2a2"
                          ref={signedRef}
                        />
                      </div>
                      <div style={{ maxWidth: 300, textAlign: 'end' }}>
                        <Button
                          size="small"
                          variant="secondary "
                          onClick={clearSignature}
                        >
                          Clear
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={isDisabledForm}
                        >
                          Submit
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Form>
        )
      ) : (
        <h3>Please Scan Barcode</h3>
      )}
    </>
  );
}
